import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccessTokenService } from 'app/auth/shared/access-token.service';
import { Observable, map, switchMap, tap } from 'rxjs';

@Injectable()
export class EventService {
  private baseUrl: string = 'https://graph.microsoft.com/v1.0';
  private bearerheader: HttpHeaders;

  constructor(
    private http: HttpClient,
    private readonly accessTokenService: AccessTokenService
  ) {
    this.initializeHeaders();
  }

  private initializeHeaders(): Observable<any> {
    return this.accessTokenService.tokenIsSetObservable().pipe(
      tap(() => {
        const auth_token = this.accessTokenService.getAuthToken();
        this.bearerheader = new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth_token}`,
        });
      })
    );
  }

  getEvents(): Observable<any[]> {
    return this.initializeHeaders().pipe(
      switchMap(() => {
        return this.http.get<any>(
          `${this.baseUrl}/me/events?$select=subject,body,bodyPreview,organizer,attendees,start,end,location`,
          { headers: this.bearerheader }
        );
      }),
      map((res) => {
        return res.value.map((event) => ({
          organizer: event.organizer.emailAddress.name,
          organizerEmail: event.organizer.emailAddress.address,
          id: event.id,
          title: event.subject,
          description: event.body.content,
          location: event.location.displayName,
          start: this.adjustTimezone(event.start.dateTime),
          end: this.adjustTimezone(event.end.dateTime),
          attendees: event.attendees.map((attendee) => ({
            name: attendee.emailAddress.name,
            address: attendee.emailAddress.address,
          })),
          backgroundColor: '#FFB6B6',
          borderColor: '#FFB6B6',
          textColor: '#212121',
          tag: { color: '#FFB6B6', name: 'Company A' },
        }));
      })
    );
  }

  setEvent(eventData: any): Promise<any> {
    const eventBody = {
      subject: eventData.title,
      body: {
        contentType: 'HTML',
        content:
          eventData.description +
          (eventData.teamsJoinURL
            ? `<br><a href="${eventData.teamsJoinURL}">Join Teams Meeting</a>`
            : ''),
      },
      start: {
        dateTime: eventData.start,
        timeZone: 'UTC',
      },
      end: {
        dateTime: eventData.end,
        timeZone: 'UTC',
      },
      location: {
        displayName: eventData.location,
      },
      attendees: eventData.attendees,
    };

    return this.initializeHeaders()
      .pipe(
        switchMap(() => {
          return this.http.post<any>(`${this.baseUrl}/me/events`, eventBody, {
            headers: this.bearerheader,
          });
        })
      )
      .toPromise();
  }

  adjustTimezone(dateString: string): Date {
    let date = new Date(dateString);
    date.setHours(date.getHours() + 3);
    return date;
  }

  getFamiliarEmails(): Observable<string[]> {
    return this.initializeHeaders().pipe(
      switchMap(() => {
        const endpoint = `${this.baseUrl}/me/people`;
        return this.http.get(endpoint, { headers: this.bearerheader });
      }),
      map((response: any) => {
        return response.value
          .map((person) =>
            person.scoredEmailAddresses && person.scoredEmailAddresses.length
              ? person.scoredEmailAddresses[0].address
              : null
          )
          .filter((email) => typeof email === 'string');
      })
    );
  }

  createTeamsMeetingAPI(event: any): Observable<any> {
    console.log('createTeamsMeetingAPI', event);
    const endpoint = `${this.baseUrl}/me/events`;

    const eventData = {
      subject: event.title,
      body: {
        contentType: 'HTML',
        content: event.description,
      },
      start: {
        dateTime: event.start,
        timeZone: 'UTC',
      },
      end: {
        dateTime: event.end,
        timeZone: 'UTC',
      },
      location: {
        displayName: event.location,
      },
      attendees: event.attendees,
      isOnlineMeeting: true,
      onlineMeetingProvider: 'teamsForBusiness',
    };

    return this.http.post(endpoint, eventData, { headers: this.bearerheader });
  }
}
