import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  userForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private readonly httpClient: HttpClient,
    private router: Router,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      username: ['', Validators.required],
    });
  }

  checkUsername() {
    this.httpClient.get<any>('User/CheckUsername/' + this.userForm.value.username)
    .subscribe(data => {
      if(data === false) {
        this.httpClient.post<any>('User/ForgetPassword/' + this.userForm.value.username, null).subscribe(() => {
          this.router.navigate(['/login'], { queryParams: { message: 'An Email Has Been Sent' } });
        });
      }
      else {
        this.messageService.add({severity:'error', summary:'Username not found'});
      }
    });
  }
}
