import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'ngx-add-item-value',
  templateUrl: './add-item-value.component.html',
  styleUrls: ['./add-item-value.component.scss']
})
export class AddItemValueComponent implements OnInit {

  item: any;
  specCategoryId: number;
  isActive: boolean = true;
  addValueForm!: UntypedFormGroup;

  itemSent: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private readonly httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.item = this.dynamicDialogConfig.data;
    this.specCategoryId = this.item.specCategoryId;
    this.addValueForm = this.formBuilder.group ({
      value: ''
    });
  }

  addItem(){
    this.itemSent = {
      specCategoryId: this.specCategoryId,
      value: this.addValueForm.value.value,
      isActive: this.isActive
    }
    this.httpClient.post<any>('Item/AddSpec', this.itemSent).subscribe();
    this.ref.close(this.addValueForm.value.value);
  }
  cancelAdd(){
    this.ref.close();
  }
}
