import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { environment } from '../../../../environments/environment.prod';
import { Item } from '../../shared/models/item.model';

@Component({
  selector: 'ngx-view-item',
  templateUrl: './view-item.component.html',
  styleUrls: ['./view-item.component.scss'],
})
export class ViewItemComponent implements OnInit {
  item: Item;

  itemFields1 = [
    { field: 'supplier', header: 'Supplier' },
    { field: 'itemCode', header: 'Item Code' },
    { field: 'family', header: 'Family' },
    { field: 'supplyPower', header: 'Supply Power' },
    { field: 'supplyCurrent', header: 'Supply Current' },

    { field: 'description', header: 'Description' },

    { field: 'hsCode', header: 'HS Code' },
    { field: 'mainCategory', header: 'Main Category' },
    { field: 'subCategory', header: 'Sub Category' },
    { field: 'installation', header: 'Installation' },
    { field: 'driver', header: 'Driver' },
  ];

  itemFields2 = [
    { field: 'housing', header: 'Housing' },
    { field: 'reflector', header: 'Reflector' },
    { field: 'diffuser', header: 'Diffuser' },
    { field: 'frame', header: 'Frame' },
    { field: 'brackets', header: 'Brackets' },
    { field: 'ipCode', header: 'IP Code' },
    { field: 'beamType', header: 'Beam Type' },
    { field: 'fittingColor', header: 'Fitting Color' },
    { field: 'ikCode', header: 'IK Code' },

    { field: 'cri', header: 'CRI' },

    { field: 'ugr', header: 'UGR' },
    { field: 'beamAngle', header: 'Beam Angle' },
  ];

  itemFields = [

    { field: 'base', header: 'Base' },


    { field: 'code', header: 'Code' },
    { field: 'colorTemp', header: 'Color Temperature' },


    { field: 'createdDate', header: 'Created Date' },
    { field: 'cutoutdiameter', header: 'Cutout Diameter' },
    { field: 'cutoutlength', header: 'Cutout Length' },


    { field: 'deleted', header: 'Deleted' },
    { field: 'diameter', header: 'Diameter' },





    { field: 'grossprice', header: 'Gross Price' },
    { field: 'hasComment', header: 'Has Comment' },
    { field: 'height', header: 'Height' },



    { field: 'isAccessory', header: 'Is Accessory' },
    { field: 'isActive', header: 'Is Active' },
    { field: 'isLocked', header: 'Is Locked' },
    { field: 'isProduct', header: 'Is Product' },


    { field: 'lamp', header: 'Lamp' },

    { field: 'lampIncluded', header: 'Lamp Included' },
    { field: 'lampSource', header: 'Lamp Source' },

    { field: 'lampsCount', header: 'Lamps Count' },
    { field: 'ledlumen', header: 'LED Lumen' },
    { field: 'length', header: 'Length' },

    { field: 'netLumen', header: 'Net Lumen' },
    { field: 'notes', header: 'Notes' },
    { field: 'preferred', header: 'Preferred' },
    { field: 'preffered', header: 'Preffered' },

    { field: 'shape', header: 'Shape' },

    { field: 'special', header: 'Special' },
    { field: 'specialPrice', header: 'Special Price' },
    { field: 'specialPriceThree', header: 'Special Price Three' },
    { field: 'specialPriceTwo', header: 'Special Price Two' },



    { field: 'totalWatt', header: 'Total Watt' },

    { field: 'updatedDate', header: 'Updated Date' },
    { field: 'voltage', header: 'Voltage' },
    { field: 'wattage', header: 'Wattage' },
    { field: 'weight', header: 'Weight' },
    { field: 'width', header: 'Width' },
  ];
  imageBaseUrl = environment.imagesBaseUrl;
  constructor(private readonly dynamicDialogConfig: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.item = this.dynamicDialogConfig.data;
  }
  imageDisplay: boolean = false;
  imageClick() {
    this.imageDisplay = true;
  }
}
