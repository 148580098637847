<section [formGroup]="messageForm">
  <div
    class="p-3 overflow-y-auto"
    style="max-height: 60vh"
    #chatWindow
    [scrollTop]="chatWindow.scrollHeight"
    *ngIf="comments && comments.length > 0"
  >
    <div *ngFor="let comment of comments">
      <div class="grid grid-nogutter">
        <div class="col mt-1">
          <p class="text-900 font-semibold mb-2">
            {{ comment.commenterUserFirstName }}
          </p>
          <div class="flex flex-row">
            <span
              class="my-custom-class"
              style="word-break: break-word; max-width: 80%"
            >
              {{ comment.message }}
              <span class="text-500 text-xs">
                {{ comment?.createdDate | date : "dd/MM/yyyy" }}
              </span>
            </span>
            <button
              *ngIf="comment.abilityToDelete"
              pButton
              pRipple
              icon="pi pi-trash"
              (click)="deleteComment(comment)"
              class="p-button-rounded p-button-text"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!comments || comments.length === 0">
    <p class="text-500 text-center">No comments yet</p>
  </div>
  <div class="flex flex-column">
    <div class="p-inputgroup mt-1">
      <input
        pInputText
        placeholder="Add Comment"
        formControlName="commnent"
        [(ngModel)]="text"
        (keydown)="handleKeyDown($event)"
        #textareaRef
      />
      <button
        type="button"
        pButton
        icon="pi pi-send"
        styleClass="p-button-warn"
        (click)="addComment()"
        *ngIf="!startedUserMention && !startedProjectMention"
      ></button>
    </div>
    <div class="dropdown-container mt-2">
      <div
        *ngFor="let user of filteredUsers"
        (click)="handleSelect(user, textareaRef)"
        class="dropdown-list"
      >
        {{ user.userName }}
      </div>
      <div
        *ngFor="let project of filteredProjects"
        (click)="handleSelectProject(project, textareaRef)"
        class="dropdown-list"
      >
        {{ project.projectName }}
      </div>
    </div>
  </div>
</section>
