<full-calendar [options]="calendarOptions"></full-calendar>

<p-dialog
  #dd
  [(visible)]="showDialog"
  [breakpoints]="{ '960px': '75vw', '640px': '90vw' }"
  [style]="{ width: '36rem' }"
  [modal]="true"
  [closable]="true"
  (onHide)="view = ''"
>
  <ng-template pTemplate="header">
    <span class="text-900 font-semibold text-xl">{{
      view === "display"
        ? changedEvent.title
        : view === "new"
        ? "New Event"
        : "Edit Event"
    }}</span>
  </ng-template>
  <ng-template pTemplate="content">
    <div *ngIf="view === 'display'">
      <p class="flex align-items-center m-0">
        <i class="pi pi-fw pi-user text-700 mr-1"></i>
        <span
          >Organizer: {{ changedEvent.organizer }} ({{
            changedEvent.organizerEmail
          }})</span
        >
      </p>
      <span class="mt-2 text-900 font-semibold block mb-2">Description</span>
      <span class="block mb-3" [innerHTML]="changedEvent.description"></span>

      <div class="grid">
        <div class="col-6">
          <div class="text-900 font-semibold mb-2">Start</div>
          <p class="flex align-items-center m-0">
            <i class="pi pi-fw pi-clock text-700 mr-2"></i>
            <span>{{ changedEvent.start.toISOString().slice(0, 10) }}</span>
          </p>
        </div>
        <div class="col-6">
          <div class="text-900 font-semibold mb-2">End</div>
          <p class="flex align-items-center m-0">
            <i class="pi pi-fw pi-clock text-700 mr-2"></i>
            <span>{{ changedEvent.end.toISOString().slice(0, 10) }}</span>
          </p>
        </div>
        <div class="col-12">
          <div class="text-900 font-semibold mb-2">Location</div>
          <p class="flex align-items-center m-0">
            <i class="pi pi-fw pi-map-marker text-700 mr-2"></i>
            <span>{{ changedEvent.location }}</span>
          </p>
        </div>
        <div class="col-12">
          <div class="text-900 font-semibold mb-2">Attendees</div>
          <ul class="attendees-list">
            <li *ngFor="let attendee of changedEvent?.attendees">
              <i class="pi pi-fw pi-user mr-2"></i>
              <span>{{ attendee?.address }} </span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div *ngIf="view !== 'display'">
      <div class="grid p-fluid formgrid">
        <div class="col-12 md:col-6 field">
          <label for="title" class="text-900 font-semibold">Title</label>
          <span class="p-input-icon-left">
            <i class="pi pi-pencil"></i>
            <input
              id="title"
              type="text"
              pInputText
              placeholder="Title"
              [(ngModel)]="changedEvent.title"
            />
          </span>
        </div>
        <div class="col-12 md:col-6 field">
          <label for="location" class="text-900 font-semibold">Location</label>
          <span class="p-input-icon-left">
            <i class="pi pi-map-marker"></i>
            <input
              id="location"
              type="text"
              pInputText
              placeholder="Location"
              [(ngModel)]="changedEvent.location"
            />
          </span>
        </div>
        <div class="col-12 field">
          <label for="description" class="text-900 font-semibold"
            >Event Description</label
          >
          <textarea
            id="description"
            type="text"
            pInputTextarea
            [rows]="5"
            [(ngModel)]="changedEvent.description"
            style="resize: none"
          ></textarea>
        </div>
        <div class="col-12 field">
          <p-autoComplete
            [(ngModel)]="selectedAttendees"
            [suggestions]="filteredAttendees"
            (completeMethod)="filterAttendees($event)"
            (keyup.enter)="addNewEmail($event)"
            multiple="true"
            placeholder="Enter emails"
            [dropdown]="true"
            [forceSelection]="false"
          >
            <ng-template let-attendee pTemplate="item">
              {{ attendee }}
            </ng-template>
          </p-autoComplete>
        </div>
        <div class="col-12 md:col-6 field">
          <label for="start" class="text-900 font-semibold">Start Date</label>
          <p-calendar
            appendTo="body"
            dateFormat="mm-dd-yy"
            [maxDate]="changedEvent.end"
            [showTime]="true"
            [required]="true"
            inputId="start"
            [(ngModel)]="changedEvent.start"
          ></p-calendar>
        </div>
        <div class="col-12 md:col-6 field">
          <label for="start" class="text-900 font-semibold">End Date</label>
          <p-calendar
            appendTo="body"
            dateFormat="mm-dd-yy"
            [minDate]="changedEvent.start"
            [showTime]="true"
            [required]="true"
            inputId="end"
            [(ngModel)]="changedEvent.end"
          ></p-calendar>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      pButton
      *ngIf="view === 'display'"
      label="Edit"
      icon="pi pi-pencil"
      (click)="onEditClick()"
    ></button>
    <button
      pButton
      *ngIf="view === 'new' || view === 'edit'"
      label="Save"
      icon="pi pi-check"
      (click)="handleSave(false)"
      [disabled]="!changedEvent.start || !changedEvent.end"
    ></button>
    <button
      pButton
      *ngIf="view === 'new' || view === 'edit'"
      label="Create Teams Meeting"
      (click)="handleSave(true)"
      [disabled]="!changedEvent.start || !changedEvent.end"
    ></button>
  </ng-template>
</p-dialog>
