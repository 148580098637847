import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FieldNames } from '../shared/enums/fields.enum';
import { AuthFacade } from '../store/auth.facade';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  messages: Message[] = [];
  public signInFormGroup!: UntypedFormGroup;
  public pending$: Observable<boolean> = this.authFacade.selectAuthPending$;

  loading: boolean = false;

  public readonly fieldNames: typeof FieldNames = FieldNames;
  isPending: boolean | undefined;

  private destroy$ = new Subject<void>();

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly authFacade: AuthFacade,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.signInFormGroup = this.initializeFormGroup();
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.message) {
        this.messages = [{ severity: 'success', summary: params.message }];
        this.messageService.add(this.messages[0]);
      }
    });
    this.subscribeToSignInResult();
  }

  submitForm(): void {
    this.authFacade.signIn(this.signInFormGroup.value);
    this.loading = true;
  }

  initializeFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [FieldNames.Username]: ['', [Validators.required]],
      [FieldNames.Password]: ['', [Validators.required]],
    });
  }

  subscribeToSignInResult(): void {
    this.authFacade.selectAuthUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe((authResponse) => {
        if (authResponse === null) {
          this.authFacade.selectAuthError$.subscribe((error) => {
            if (error) {
              this.handleSignInFailure(error);
            }
          });
          return;
        }

        if (authResponse && authResponse.userId === -1) {
          this.handleSignInFailure('Wrong credentials. Please try again.');
        }
      });

    this.authFacade.selectAuthPending$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isPending) => {
        this.loading = isPending;
      });
  }

  handleSignInFailure(message: string): void {
    this.loading = false;
    this.messages = [{ severity: 'error', summary: message }];
    this.messageService.add({ severity: 'error', summary: message });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
