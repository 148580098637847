import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { CreateCommentComponent } from 'app/shared/components/create-comment/create-comment.component';
import { ViewColumnsComponent } from 'app/shared/components/view-columns/view-columns.component';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { ContactService } from '../Services/Contact/contact.service';
import { NewCompanyComponent } from '../companies/dialogues/new-company/new-company.component';
import { Contact } from '../companies/shared/models/contact.model';
import { AddContactComponent } from './dialogues/add-contact/add-contact.component';
import { DeleteContactComponent } from './dialogues/delete-contact/delete-contact.component';

@Component({
  selector: 'ngx-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements OnInit {
  message: Message[] = [];

  contacts: any;
  loading!: boolean;

  searchFormGroup!: FormGroup;

  columnsForm!: FormGroup;
  checkedColumns: Contact[] = [];

  ribbon: MenuItem[] = [
    {
      label: 'Add Company',
      icon: 'pi pi-plus',
      command: () => {
        if (this.AddPermission) {
          this.dialogService
            .open(NewCompanyComponent, {
              data: [{}, 'add'],
              header: 'Add Company',
              width: '100%',
              height: '90%',
            })
            .onClose.subscribe((data) => {
              if (data) {
                this.message = [
                  {
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Company Added Successfully',
                  },
                ];
                this.messageService.add(this.message[0]);
              }
            });
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: 'You do not have permission to add company',
          });
        }
      },
    },
    {
      label: 'Filter Columns',
      icon: 'pi pi-filter',
      command: () => {
        this.dialogService.open(ViewColumnsComponent, {
          data: [this.columnsForm, 'contact'],
          header: 'Visible Columns',
          width: '70%',
        });
      },
    },

    {
      label: 'Filter Contacts',
      icon: 'pi pi-filter',
      command: () => {
        this.showAccordion();
      },
    },
  ];

  cols = [
    { field: 'contactId', header: 'Contact ID' },
    { field: 'firstName', header: 'Name' },
    { field: 'companyName', header: 'Company Name' },
    { field: 'salesmanName', header: 'Salesman Name' },
    { field: 'positionTitle', header: 'Position' },
    { field: 'professionalEmail', header: 'Email' },
    { field: 'personalEmail', header: 'Personal Email' },
    { field: 'mobile', header: 'Mobile' },
    { field: 'country', header: 'Country' },
    { field: 'skypeName', header: 'Skype' },
    { field: 'teams', header: 'Teams' },
    { field: 'area', header: 'Area' },
    { field: 'classification', header: 'Classification' },
    { field: 'companyEmail', header: 'Company Email' },
    { field: 'companySubType', header: 'Company Sub Type' },
    { field: 'ksaArea', header: 'KSA Area' },
    { field: 'remarks', header: 'Remarks' },
  ];

  constructor(
    private readonly httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder,
    public dialogService: DialogService,
    private messageService: MessageService,
    private ContactService: ContactService,
    private storage: StorageMap,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.searchFormGroup = this.formBuilder.group({
      searchText: null,
      companyTypeId: null,
      companySubTypeId: null,
      positionId: null,
      countryId: null,
      pageNumber: 0,
      orderByCompanyName: false,
      date1: null,
      date2: null,
      salesmanId: null,
      commentEntityId: null,
    });
    this.checkParams();

    this.columnsForm = this.formBuilder.group({
      area: false,
      classification: false,
      companyEmail: false,
      companyName: true,
      companySubType: false,
      companyType: true,
      contactId: false,
      country: true,
      ext: false,
      firstName: true,
      ksaArea: false,
      lastName: false,
      middleName: false,
      mobile: true,
      other: false,
      personalEmail: false,
      phone: false,
      positionTitle: true,
      professionalEmail: true,
      remarks: false,
      salesmanName: true,
      skypeName: false,
      teams: true,
    });
    this.checkedColumns = [this.columnsForm.value];
    this.columnsForm.valueChanges.subscribe((event) => {
      this.checkedColumns = [event];
    });

    this.searchFormGroup.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => (this.loading = true)),
        switchMap(() => this.searchAndPaginate())
      )
      .subscribe();

    this.Authentication();

    this.getCompanyTypes();
    this.getCountries();
    this.getSalesman();
    this.getPosition();
  }

  showEditContact(contact: Contact) {
    this.dialogService
      .open(AddContactComponent, {
        data: [contact, null, false, false],
        header: 'Edit Contact: ' + contact.firstName,
        width: '80%',
      })
      .onClose.subscribe((updatedContact: Contact) => {
        if (updatedContact) {
          const index = this.contacts.findIndex(
            (c) => c.contactId === updatedContact.contactId
          );
          if (index !== -1) {
            this.contacts[index] = updatedContact;
          }
          this.message = [
            {
              severity: 'success',
              summary: 'Success',
              detail: 'Contact Updated Successfully',
            },
          ];
          this.messageService.add(this.message[0]);
        }
        this.searchAndPaginate().subscribe();
      });
  }

  showViewContact(contact: Contact) {
    this.dialogService.open(AddContactComponent, {
      data: [contact, null, null, true],
      header: 'View Contact: ' + contact.firstName,
      width: '70%',
    });
  }

  showDeleteContact(contact: Contact) {
    this.dialogService
      .open(DeleteContactComponent, {
        data: contact,
        header: 'Delete Contact',
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.message = [
            {
              severity: 'success',
              summary: 'Success',
              detail: 'Item Deleted Successfully',
            },
          ];
          this.messageService.add(this.message[0]);
        }
        this.searchAndPaginate().subscribe();
      });
  }

  checkParams() {
    this.route.queryParams.subscribe((params) => {
      const entityId = params['id'];
      if (entityId) {
        this.searchFormGroup.get('commentEntityId')?.patchValue(entityId);
      }
      this.searchAndPaginate().subscribe();
    });
  }

  searchAndPaginate(): Observable<any> {
    return this.httpClient
      .post('Contact/ContactSearch', this.searchFormGroup.value)
      .pipe(
        tap((data) => {
          this.contacts = data;
          this.loading = false;
        })
      );
  }

  paginate(event: any) {
    this.searchFormGroup.get('pageNumber')?.patchValue(event.page + 1);
  }

  exportContacts() {
    this.httpClient.get<any>('Contact/GetAllContacts').subscribe((data) => {
      import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        this.saveAsExcelFile(excelBuffer, 'All Contacts');
      });
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver) => {
      let EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }

  clearSearch() {
    this.searchFormGroup.get('searchText')?.patchValue(null);
  }

  exportToExcel() {
    this.ContactService.GetAllContactSearch(
      this.searchFormGroup.value
    ).subscribe((res) => {
      const fileName = 'Contacts.xlsx';
      var printableArray = [];
      res.forEach((el) => {
        let obj = {
          FirstName: el.firstName,
          LastName: el.lastName,
          Ext: el.ext,
          Mobile: el.mobile,
          ProfessionalEmail: el.professionalEmail,
          Position: el.positionTitle,
          CompanyName: el.companyName,
          CompanyType: el.companyType,
          CompanySubType: el.companySubType,
          CompanyEmail: el.companyEmail,
          Country: el.country,
          Area: el.ksaArea != null ? el.ksaArea : el.area,
          Classification: el.classification,

          SalesmanName: el.salesmanName,
        };

        printableArray.push(obj);
      });

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(printableArray);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Contacts');
      XLSX.writeFile(wb, fileName);
    });
  }

  Identity: any;
  ActiveUserId: any;
  Permissions: any;
  AddPermission: boolean = false;
  EditPermission: boolean = false;
  ViewPermission: boolean = false;
  DeletePermission: boolean = false;
  Authentication() {
    this.storage.get('Identity').subscribe((user) => {
      this.Identity = user;
      this.ActiveUserId = this.Identity.userId;
      this.Permissions = this.Identity.permissions;
      this.Permissions.companies.forEach((element) => {
        if (element === 2) {
          this.AddPermission = true;
        } else if (element === 3) {
          this.EditPermission = true;
        } else if (element === 1) {
          this.ViewPermission = true;
        } else if (element === 4) {
          this.DeletePermission = true;
        }
      });
    });
  }

  countryItems: any[] = [];
  companyTypeItems: any[] = [];
  companySubTypeItems: any[] = [];
  positionItems: any[] = [];
  toggleName = 'Order By Company Name';
  salesman: any;

  onCompanyTypeChange(companyTypeId: any) {
    if (companyTypeId.value != null) {
      this.httpClient
        .get<any>(
          'company/GetCompanySubTypesByCompanyTypeId/' + companyTypeId.value
        )
        .subscribe((companySubTypes) => {
          this.companySubTypeItems = companySubTypes.map((item) => ({
            title: item.title,
            companySubTypeId: item.companySubTypeId,
          }));
        });
    }
  }

  getSalesman() {
    this.httpClient.get<any>('User/GetUsersByRoleID/' + 4).subscribe((data) => {
      this.salesman = data;
      this.httpClient
        .get<any>('User/GetUsersByRoleID/' + 6)
        .subscribe((data) => {
          this.salesman = [...this.salesman, ...data];
        });
    });
  }

  getCountries() {
    this.httpClient
      .get<any>('Settings/GetAllCountries')
      .subscribe((countries) => {
        this.countryItems = countries.map((item) => ({
          title: item.title,
          countryId: item.countryId,
        }));
      });
  }

  getCompanyTypes() {
    this.httpClient.get<any>('company/GetCompanyTypes').subscribe((data) => {
      this.companyTypeItems = data.map((item) => ({
        title: item.title,
        companyTypeId: item.companyTypeId,
      }));
    });
  }
  getPosition() {
    this.httpClient
      .get<any>('company/GetAllBasicsByCategoryId/' + 5)
      .subscribe((data) => {
        this.positionItems = data.map((item) => ({
          title: item.value,
          positionId: item.basicId,
        }));
      });
  }
  activeAccordion: boolean = false;

  showAccordion() {
    this.activeAccordion = !this.activeAccordion;
  }

  showAddComment(contact: Contact) {
    this.dialogService.open(CreateCommentComponent, {
      data: {
        entityId: contact.contactId,
        userId: this.ActiveUserId,
        entityTypeId: 27,
      },
      header: 'Add Comment',
      height: '70%',
      width: '70%',
    });
  }
}
