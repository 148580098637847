export const environment = {
  ForexUrl:
    'https://cors-anywhere.herokuapp.com/http://data.fixer.io/api/latest?access_key=1629705008c45bd95acd4bdd12eca1aa&format=1&base=EUR&symbols=QAR,USD,JPY,EUR',

  ForexToEur:
    'https://thingproxy.freeboard.io/fetch/https://free.currconv.com/api/v7/convert?q=USD_QAR,EUR_QAR&apiKey=41759517d2657cd09539',

  baseUrlForex: 'https://free.currconv.com/',
  production: true,

  //Production
  // baseUrl: 'https://apollolightingapp.com/api/v1/',
  // imagesBaseUrl: 'https://apollolightingapp.com/api/',
  // SignalRBaseUrl: 'https://apollolightingapp.com/api/chat/',
  // O365RedirectUrl: 'https://apollolightingapp.com' 

  //UAT
  baseUrl: 'http://20.124.22.192/apiuat/v1/',
  imagesBaseUrl: 'http://20.124.22.192/apiuat/',
  SignalRBaseUrl: 'http://20.124.22.192/apiuat/chat/',
  O365RedirectUrl: 'http://20.124.22.192' 
 

  //Local Development
  // baseUrl: 'https://localhost:5001/v1/',
  // imagesBaseUrl: 'https://localhost:5001/',
  // SignalRBaseUrl: 'https://localhost:5001/chat/',
  // O365RedirectUrl: 'http://localhost:4200' 
};
