import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { EventService } from 'app/shared/services/event.service';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  standalone: true,
  imports: [
    InputTextModule,
    DialogModule,
    CalendarModule,
    FullCalendarModule,
    ButtonModule,
    InputTextareaModule,
    DynamicDialogModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutoCompleteModule,
  ],
  providers: [EventService],
})
export class CalendarComponent implements OnInit {
  calendarOptions: any;
  emails: any[] = [];
  selectedAttendees: any[] = [];
  filteredAttendees: any[] = [];

  sanitizedDescription: SafeHtml;

  constructor(
    private readonly eventService: EventService,

    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.eventService.getFamiliarEmails().subscribe((emailsList) => {
      this.emails = emailsList;
    });

    this.initCalendar();
  }

  filterAttendees(event) {
    let query = event.query;
    this.filteredAttendees = this.emails.filter((email) => {
      if (typeof email === 'string') {
        return email.toLowerCase().includes(query.toLowerCase());
      }
      return false;
    });
  }

  initCalendar() {
    this.today = new Date().toISOString().split('T')[0];

    this.eventService.getEvents().subscribe({
      next: (events) => {
        this.events = events;
        this.calendarOptions = { ...this.calendarOptions, events };
        this.tags = this.events.map((item) => item.tag);
      },
    });

    this.calendarOptions = {
      ...this.calendarOptions,

      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      height: 550,
      initialDate: this.today,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      },
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      eventClick: (e) => this.onEventClick(e),
      select: (e) => this.onDateSelect(e),
    };
  }

  events: any[] = [];

  today: string = '';

  showDialog: boolean = false;

  clickedEvent: any = null;

  dateClicked: boolean = false;

  edit: boolean = false;

  tags: any[] = [];

  view: string = '';

  changedEvent: any;

  onEventClick(e: any) {
    this.selectedAttendees = [];
    this.filteredAttendees = [];

    this.clickedEvent = e.event;
    let plainEvent = e.event.toPlainObject({
      collapseExtendedProps: true,
      collapseColor: true,
    });
    this.view = 'display';
    this.showDialog = true;

    this.changedEvent = { ...plainEvent, ...this.clickedEvent };
    this.changedEvent.start = this.clickedEvent.start;
    this.changedEvent.end = this.clickedEvent.end
      ? this.clickedEvent.end
      : this.clickedEvent.start;

    this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(
      this.changedEvent.description
    );
  }

  onDateSelect(e: any) {
    this.selectedAttendees = [];
    this.filteredAttendees = [];

    this.view = 'new';
    this.showDialog = true;
    this.changedEvent = {
      ...e,
      title: null,
      description: null,
      location: null,
      backgroundColor: null,
      borderColor: null,
      textColor: null,
      tag: { color: null, name: null },
    };
  }
  onEditClick() {
    this.view = 'edit';
  }

  delete() {
    this.events = this.events.filter(
      (i) => i.id.toString() !== this.clickedEvent.id.toString()
    );
    this.calendarOptions = {
      ...this.calendarOptions,
      ...{ events: this.events },
    };
    this.showDialog = false;
  }

  validate() {
    let { start, end } = this.changedEvent;
    return start && end;
  }

  addNewEmail(event: any) {
    const inputValue = event.target.value.trim();
    if (
      inputValue &&
      this.isEmailValid(inputValue) &&
      !this.selectedAttendees.includes(inputValue)
    ) {
      const newEmail = inputValue;
      this.selectedAttendees.push(newEmail);

      this.filteredAttendees.push(newEmail);
      event.target.value = '';
    }
  }

  isEmailValid(email: string): boolean {
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  }

  handleSave(createTeamsMeeting: boolean) {
    if (!this.validate()) {
      return;
    }

    this.showDialog = false;
    this.clickedEvent = {
      ...this.changedEvent,
      backgroundColor: this.changedEvent.tag.color,
      borderColor: this.changedEvent.tag.color,
      textColor: '#212121',
    };

    if (this.clickedEvent.hasOwnProperty('id')) {
      this.events = this.events.map((i) =>
        i.id.toString() === this.clickedEvent.id.toString()
          ? (i = this.clickedEvent)
          : i
      );
    } else {
      this.events = [
        ...this.events,
        { ...this.clickedEvent, id: Math.floor(Math.random() * 10000) },
      ];
    }

    const eventData = this.prepareEventData();
    console.log('Prepared eventData:', eventData);

    if (createTeamsMeeting) {
      console.log('Creating Teams meeting...');
      this.createTeamsMeeting(eventData);
    } else {
      this.eventService
        .setEvent(eventData)
        .then(() => {
          alert('Event saved successfully!');
        })
        .catch((err) => {
          console.error('Error while creating event:', err);
        });
    }

    this.calendarOptions = {
      ...this.calendarOptions,
      ...{ events: this.events },
    };
    this.clickedEvent = null;
  }

  createTeamsMeeting(eventData) {
    this.eventService.createTeamsMeetingAPI(eventData).subscribe({
      next: (response) => {
        console.log('Teams meeting created successfully:', response);
        const teamsJoinURL = response.onlineMeeting.joinUrl;

        eventData.teamsJoinURL = teamsJoinURL;

        alert('Teams meeting created successfully!'); // Just alert for success. No more API call to setEvent here.
      },
      error: (error) => {
        console.error('Error creating Teams meeting:', error);
        alert('Failed to create Teams meeting. Please try again.');
      },
    });
  }

  prepareEventData() {
    // Make sure selectedAttendees are strings representing emails.
    if (!this.selectedAttendees.every((email) => typeof email === 'string')) {
      console.error(
        'selectedAttendees contains non-string items:',
        this.selectedAttendees
      );
      return null; // or handle this case appropriately
    }

    const formattedAttendees = this.selectedAttendees.map((email) => ({
      emailAddress: {
        address: email,
        name: email, // Using the email as the name for simplicity. Adjust if needed.
      },
      type: 'required',
    }));

    const eventData = {
      title: this.clickedEvent.title,
      description: this.clickedEvent.description,
      start: new Date(this.clickedEvent.start).toISOString().split('.')[0],
      end: new Date(this.clickedEvent.end).toISOString().split('.')[0],
      location: this.clickedEvent.location,
      attendees: formattedAttendees,
    };

    return eventData;
  }
}
