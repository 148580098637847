<p-messages class="w-3 absolute right-0 z-3" [(value)]="message"></p-messages>
<p-menubar [model]="ribbon">
  <ng-template pTemplate="end">
    <form [formGroup]="searchFormGroup" class="flex flex-row">
      <div class="p-inputgroup">
        <input
          pInputText
          type="text"
          class="p-2"
          [formControlName]="'searchText'"
          placeholder="Search"
        />
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-times"
          styleClass="p-button-danger"
          (click)="clearSearch()"
        ></button>
      </div>
    </form>
  </ng-template>
</p-menubar>
<form
  [formGroup]="searchFormGroup"
  class="flex flex-row gap-2 p-2"
  *ngIf="activeAccordion"
>
  <p-dropdown
    [options]="companyTypeItems"
    formControlName="companyTypeId"
    (onChange)="onCompanyTypeChange($event)"
    optionLabel="title"
    [filter]="true"
    filterBy="title"
    [showClear]="true"
    placeholder="Company Type"
    optionValue="companyTypeId"
  >
  </p-dropdown>
  <p-dropdown
    [options]="companySubTypeItems"
    formControlName="companySubTypeId"
    optionLabel="title"
    [filter]="true"
    filterBy="title"
    [showClear]="true"
    placeholder="Company SubType"
    optionValue="companySubTypeId"
  >
  </p-dropdown>
  <p-dropdown
    [options]="countryItems"
    formControlName="countryId"
    optionLabel="title"
    [filter]="true"
    filterBy="title"
    [showClear]="true"
    placeholder="Country"
    optionValue="countryId"
  >
  </p-dropdown>
  <p-dropdown
    [options]="positionItems"
    formControlName="positionId"
    optionLabel="title"
    [filter]="true"
    filterBy="title"
    [showClear]="true"
    placeholder="Position"
    optionValue="positionId"
  >
  </p-dropdown>
  <p-dropdown
    [options]="salesman"
    formControlName="salesmanId"
    optionLabel="userName"
    [filter]="true"
    filterBy="userName"
    [showClear]="true"
    placeholder="Salesman"
    optionValue="userId"
  >
  </p-dropdown>
  <p-toggleButton
    formControlName="orderByCompanyName"
    [onLabel]="toggleName"
    [offLabel]="toggleName"
  >
  </p-toggleButton>
</form>

<section>
  <p-table [value]="contacts" [loading]="loading" [columns]="cols">
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between gap-2 mr-4">
        <h1 class="text-4xl">List of Contacts</h1>
        <div class="flex gap-1">
          <p-overlayPanel #op>
            <app-calendar></app-calendar>
          </p-overlayPanel>
          <button
            pButton
            pTooltip="Calendar"
            (click)="op.toggle($event)"
            icon="pi pi-calendar"
            class="p-button-rounded p-button-outlined"
          ></button>
          <button
            type="button"
            pButton
            pRipple
            icon="pi pi-file"
            (click)="exportToExcel()"
            class="p-button-rounded p-button-outlined"
            pTooltip="Export contacts"
            tooltipPosition="top"
          ></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns">
          <th *ngIf="columnsForm.get(col.field).value">
            {{ col.header }}
          </th>
        </ng-container>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product let-columns="columns">
      <tr>
        <ng-container *ngFor="let col of columns">
          <td
            *ngIf="columnsForm.get(col.field).value"
            style="word-wrap: break-word"
          >
            <div class="flex flex-row align-items-center">
              <span
                *ngIf="
                  col.field == 'professionalEmail' && product[col.field] != null
                "
              >
                <a
                  href="mailto:{{ product[col.field] }}"
                  pButton
                  type="button"
                  icon="pi pi-envelope"
                  class="p-button-rounded p-button-text align-self-center"
                ></a>
              </span>
              <span *ngIf="col.field == 'mobile' && product[col.field] != null">
                <a
                  href="tel:{{ product[col.field] }}"
                  pButton
                  type="button"
                  icon="pi pi-phone"
                  class="p-button-rounded p-button-text align-self-center"
                ></a>
                <a
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-comments"
                  class="p-button-rounded p-button-text"
                  href="https://wa.me/{{ product[col.field] }}"
                  target="_blank"
                ></a>
              </span>

              {{
                col.field === "firstName"
                  ? (product[col.field] || "") +
                    (product.middleName ? " " + product.middleName : "") +
                    (product.lastName ? " " + product.lastName : "")
                  : product[col.field]
              }}
            </div>
          </td>
        </ng-container>
        <td>
          <button
            pButton
            pRipple
            icon="pi pi-eye"
            (click)="showViewContact(product)"
            class="p-button-rounded p-button-text"
          ></button>
          <button
            pButton
            pRipple
            icon="pi pi-pencil"
            (click)="showEditContact(product)"
            class="p-button-rounded p-button-text"
          ></button>
          <button
            pButton
            pRipple
            icon="pi pi-trash"
            (click)="showDeleteContact(product)"
            class="p-button-rounded p-button-text"
          ></button>
          <button
            pButton
            pRipple
            icon="pi pi-comment"
            (click)="showAddComment(product)"
            class="p-button-rounded p-button-text"
            pTooltip="Add Comment"
            tooltipPosition="top"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator
    [rows]="10"
    [totalRecords]="1500"
    (onPageChange)="paginate($event)"
  >
  </p-paginator>
</section>
