import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';

@Injectable()
export class CustomHttpInterceptorComponent implements HttpInterceptor {
  constructor(private storage: StorageMap) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      // req.url.endsWith('https://login.microsoftonline') ||
      req.url.includes('graph.microsoft.com') ||
      req.url.includes('.json') ||
      req.url.includes('apollolightingapp.com/api/chat')
    ) {
      return next.handle(req);
    }

    return this.storage.get('Identity').pipe(
      switchMap((user: any) => {
        let headers = req.headers;
        if (user && user.apolloToken && req.url !== '/login') {
          headers = headers.set('Authorization', `Bearer ${user.apolloToken}`);
        }
        const customReq = req.clone({
          url: environment.baseUrl + req.url,
          headers: headers.set(
            'Access-Control-Allow-Origin',
            environment.baseUrl
          ),
        });

        return next.handle(customReq);
      })
    );
  }
}
