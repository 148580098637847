<p-toast></p-toast>
<form [formGroup]="addItemForm" [class.disabled-form]="viewItem">
  <div class="flex flex-row gap-2">
    <div class="card w-full">
      <h5 class="text-lg">BASIC INFO</h5>
      <div class="flex flex-column gap-1">
        <span class="flex flex-column">
          <label>* Supplier</label>
          <div class="flex flex-row">
            <p-dropdown
              [style]="{ height: '2.3rem' }"
              [options]="suppliers"
              formControlName="supplierId"
              optionLabel="companyName"
              optionValue="companyId"
              placeholder=" "
              [filter]="true"
              filterBy="companyName"
              [showClear]="true"
            ></p-dropdown>
            <!-- <p-button
              icon="pi pi-plus"
              (click)="addSupplier()"
              class="custom-button"
            ></p-button> -->
          </div>
        </span>
        <div *ngFor="let item of basicItemInputCategories">
          <span class="flex flex-column">
            <label>{{ item.header }}:</label>
            <input type="text" pInputText [formControlName]="item.field" />
          </span>
        </div>
        <span class="flex flex-column">
          <label>Description:</label>
          <textarea
            rows="1"
            class="w-full"
            pInputTextarea
            autoResize="autoResize"
            formControlName="description"
          ></textarea>
        </span>
        <span class="flex flex-column">
          <label>Quick Description:</label>
          <textarea
            rows="1"
            class="w-full"
            pInputTextarea
            autoResize="autoResize"
            formControlName="mainDescription"
          ></textarea>
        </span>
        <span class="flex flex-column">
          <label>HS Code:</label>
          <p-dropdown
            autoWidth="false"
            [style]="{ height: '2.3rem' }"
            [options]="HSCodes"
            formControlName="hscodeid"
            placeholder=" "
            optionLabel="hsCode"
            optionValue="hsCodeId"
            [filter]="true"
            filterBy="value"
            [showClear]="true"
          ></p-dropdown>
        </span>
        <ng-container *ngFor="let category of basicItemDropDownCategories">
          <span class="flex flex-column">
            <label>{{ category.name }}</label>
            <span class="flex flex-row">
              <p-dropdown
                autoWidth="false"
                [style]="{ height: '2.3rem' }"
                [options]="category.options"
                formControlName="{{ category.id }}"
                placeholder=" "
                optionLabel="value"
                optionValue="specId"
                [filter]="true"
                filterBy="value"
                [showClear]="true"
              >
              </p-dropdown>
              <!-- <p-button
                icon="pi pi-plus"
                (click)="addItemValue(category)"
                class="custom-button"
              ></p-button> -->
            </span>
          </span>
        </ng-container>
        <div
          class="flex flex-wrap justify-content-between align-items-center mt-2 gap-1"
        >
          <div *ngFor="let item of itemCheckBoxCategories">
            <p-checkbox
              binary="true"
              [name]="item.header"
              [formControlName]="item.field"
              [label]="item.header"
            ></p-checkbox>
          </div>
          <div *ngIf="addItemForm.get('preferred').value == true">
            <div *ngFor="let item of preferredChckBoxCategories">
              <p-checkbox
                binary="true"
                [name]="item.header"
                [formControlName]="item.field"
                [label]="item.header"
              ></p-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card w-full" *ngIf="!isPowerSupply">
      <h5 class="text-lg">BODY</h5>
      <div class="flex flex-column gap-1">
        <ng-container *ngFor="let category of bodyItemDropDownCategories">
          <span class="flex flex-column">
            <label>{{ category.name }}</label>
            <span class="flex flex-row">
              <p-dropdown
                autoWidth="false"
                [style]="{ height: '2.3rem' }"
                [options]="category.options"
                formControlName="{{ category.id }}"
                placeholder=" "
                optionLabel="value"
                optionValue="specId"
                [filter]="true"
                filterBy="value"
                [showClear]="true"
              >
              </p-dropdown>
              <!-- <p-button
                icon="pi pi-plus"
                (click)="addItemValue(category, bodyItemDropDownCategories)"
                class="custom-button"
              ></p-button> -->
            </span>
          </span>
        </ng-container>

        <div *ngFor="let item of bodyItemInputCategories">
          <span class="flex flex-column">
            <label>{{ item.header }}:</label>
            <input type="text" pInputText [formControlName]="item.field" />
          </span>
        </div>
      </div>
    </div>
    <div class="card w-full" *ngIf="isPowerSupply">
      <h5 class="text-lg">BODY</h5>
      <div class="flex flex-column gap-1">
        <ng-container *ngFor="let category of voltageItemDropDownCategories">
          <span class="flex flex-column">
            <label>{{ category.name }}</label>
            <span class="flex flex-row"
              ><p-dropdown
                autoWidth="false"
                [style]="{ height: '2.3rem' }"
                [options]="category.options"
                formControlName="{{ category.id }}"
                placeholder=" "
                optionLabel="value"
                optionValue="specId"
                [filter]="true"
                filterBy="value"
                [showClear]="true"
              >
              </p-dropdown>
              <!-- <p-button
                icon="pi pi-plus"
                (click)="addItemValue(category, voltageItemDropDownCategories)"
                class="custom-button"
              ></p-button -->
              ></span
            >
          </span>
        </ng-container>

        <div *ngFor="let item of voltageItemInputCategories">
          <span class="flex flex-column">
            <label>{{ item.header }}:</label>
            <input type="text" pInputText [formControlName]="item.field" />
          </span>
        </div>
      </div>
    </div>

    <div class="card w-full" *ngIf="!isPowerSupply">
      <h5 class="text-lg">LAMP SPECS</h5>
      <div class="flex flex-column gap-1">
        <ng-container *ngFor="let category of lampItemDropDownCategories">
          <span class="flex flex-column">
            <label>{{ category.name }}</label>
            <span class="flex flex-row">
              <p-dropdown
                autoWidth="false"
                [style]="{ height: '2.3rem' }"
                [options]="category.options"
                formControlName="{{ category.id }}"
                placeholder=" "
                optionLabel="value"
                optionValue="specId"
                [filter]="true"
                filterBy="value"
                [showClear]="true"
              >
              </p-dropdown>
              <!-- <p-button
                icon="pi pi-plus"
                (click)="addItemValue(category, lampItemDropDownCategories)"
                class="custom-button"
              ></p-button> -->
            </span>
          </span>
        </ng-container>
        <span class="flex flex-column">
          <label>Lamps Count</label>
          <p-inputNumber formControlName="lampsCount"></p-inputNumber>
        </span>
        <span class="flex flex-column">
          <label>Wattage</label>
          <p-inputNumber
            formControlName="wattage"
            mode="decimal"
            [minFractionDigits]="2"
            [maxFractionDigits]="5"
          ></p-inputNumber>
        </span>
        <span class="flex flex-column">
          <label>Total Wattage</label>
          <p-inputNumber
            formControlName="totalWatt"
            mode="decimal"
            [minFractionDigits]="2"
            [maxFractionDigits]="5"
            readonly
          ></p-inputNumber>
        </span>
        <div *ngFor="let item of lampItemInputCategories">
          <span class="flex flex-column">
            <label>{{ item.header }}:</label>
            <input type="text" pInputText [formControlName]="item.field" />
          </span>
        </div>
        <div class="flex flex-row gap-2">
          <p-button label="Image" (click)="img.click()"
            ><input
              accept="image/x-png,image/gif,image/jpeg"
              type="file"
              #img
              placeholder="Choose file"
              (change)="AddImage($event)"
              style="display: none"
              multiple
          /></p-button>
          <p-button
            *ngIf="selectedImageIndex !== null"
            label="Set as Default"
            (click)="setDefault(selectedImageIndex)"
          >
          </p-button>
        </div>
        <div *ngIf="images" class="flex flex-wrap gap-1">
          <div
            *ngFor="let image of images; let index = index"
            key="index"
            class="flex flex-column relative"
          >
            <img
              class="img-thumbnail"
              [src]="
                CurrentItemId == 0 && DuplicatedFromItemId == 0
                  ? image.url
                  : imageBaseUrl + 'resources/images/' + image.name
              "
              style="cursor: pointer"
              [class.selected]="selectedImageIndex === index"
              (click)="imageClick(index)"
            />
            <button
              *ngIf="editItem == true"
              class="select"
              (click)="selectImage(index)"
            >
              o
            </button>
            <button class="delete-button" (click)="deleteImage(index, $event)">
              x
            </button>
          </div>
        </div>

        <button
          pButton
          pRipple
          type="button"
          label="Upload Documents"
          (click)="showAddDocuments()"
          class="p-button-outlined"
        ></button>
      </div>
    </div>

    <div class="card w-full" *ngIf="isPowerSupply">
      <h5 class="text-lg">SPECS</h5>
      <div class="flex flex-column gap-1">
        <div *ngFor="let item of extravoltageItemInputCategories">
          <span class="flex flex-column">
            <label>{{ item.header }}:</label>

            <input type="text" pInputText [formControlName]="item.field" />
          </span>
        </div>

        <div class="flex flex-row gap-2">
          <p-button label="Image" (click)="img.click()"
            ><input
              accept="image/x-png,image/gif,image/jpeg"
              type="file"
              #img
              placeholder="Choose file"
              (change)="AddImage($event)"
              style="display: none"
              multiple
          /></p-button>
          <p-button
            *ngIf="selectedImageIndex !== null"
            label="Set as Default"
            (click)="setDefault(selectedImageIndex)"
          >
          </p-button>
        </div>
        <div *ngIf="images" class="flex flex-wrap gap-1">
          <div
            *ngFor="let image of images; let index = index"
            key="index"
            class="flex flex-column relative"
          >
            <img
              class="img-thumbnail"
              [src]="
                CurrentItemId == 0 && DuplicatedFromItemId == 0
                  ? image.url
                  : imageBaseUrl + 'resources/images/' + image.name
              "
              style="cursor: pointer"
              [class.selected]="selectedImageIndex === index"
              (click)="imageClick(index)"
            />
            <button
              *ngIf="editItem == true"
              class="select"
              (click)="selectImage(index)"
            >
              o
            </button>
            <button class="delete-button" (click)="deleteImage(index, $event)">
              x
            </button>
          </div>
        </div>
        <button
          pButton
          pRipple
          type="button"
          label="Upload Documents"
          (click)="showAddDocuments()"
          class="p-button-outlined"
        ></button>
      </div>
    </div>

    <div class="card w-full">
      <h5 class="text-lg">SHAPE/DIMENSIONS</h5>
      <div class="flex flex-column gap-1">
        <ng-container *ngFor="let category of shapeItemDropDownCategories">
          <span class="flex flex-column">
            <label>{{ category.name }}</label>
            <span class="flex flex-row">
              <p-dropdown
                autoWidth="false"
                [style]="{ height: '2.3rem' }"
                [options]="category.options"
                formControlName="{{ category.id }}"
                placeholder=" "
                optionLabel="value"
                optionValue="specId"
                [filter]="true"
                filterBy="value"
                [showClear]="true"
              >
              </p-dropdown>
              <!-- <p-button
                icon="pi pi-plus"
                (click)="addItemValue(category, shapeItemDropDownCategories)"
                class="custom-button"
              ></p-button> -->
            </span>
          </span>
        </ng-container>

        <div *ngFor="let item of shapeItemInputCategories">
          <span class="flex flex-column">
            <label>{{ item.header }}:</label>

            <input type="text" pInputText [formControlName]="item.field" />
          </span>
        </div>
      </div>
    </div>

    <div class="card w-full">
      <h5 class="text-lg">PRICE</h5>
      <div class="flex flex-column gap-1">
        <span class="flex flex-column">
          <label>* Supplier Currency</label>
          <p-dropdown
            autoWidth="false"
            [style]="{ height: '2.3rem' }"
            [options]="suppliersCurrency"
            formControlName="supplierCurrencyId"
            placeholder=" "
            optionLabel="currency.symbol"
            optionValue="companyCurrenciesId"
            [filter]="true"
            filterBy="value"
            [showClear]="true"
          ></p-dropdown>
        </span>

        <span class="flex flex-column">
          <label>Gross Price</label>
          <p-inputNumber
            formControlName="grossPrice"
            mode="decimal"
            [minFractionDigits]="2"
            [maxFractionDigits]="5"
          ></p-inputNumber>
        </span>
        <span class="flex flex-column">
          <label>Discount:</label>
          <p-inputNumber
            formControlName="discount"
            mode="decimal"
            [minFractionDigits]="2"
            [maxFractionDigits]="5"
            suffix="%"
          ></p-inputNumber>
        </span>
        <input
          type="text"
          pInputText
          [value]="'Net Price: ' + addItemForm.get('discountedPrice').value"
          readonly
        />
        <div *ngFor="let item of extraPriceItemInputCategories">
          <span class="flex flex-column">
            <label>{{ item.header }}:</label>
            <p-inputNumber
              [formControlName]="item.field"
              inputId="minmaxfraction"
              mode="decimal"
              [minFractionDigits]="2"
              [maxFractionDigits]="5"
            >
            </p-inputNumber>
          </span>
        </div>
        <span class="flex flex-column">
          <label>Notes:</label>

          <input type="text" pInputText formControlName="notes" />
        </span>
        <span *ngIf="viewItem" class="flex flex-column">
          <label>Updated Date</label>
          <input type="text" pInputText formControlName="updatedDate" />
          <label>Created Date</label>
          <input type="text" pInputText formControlName="createdDate" />
        </span>
        <button
          *ngIf="!viewItem"
          pButton
          pRipple
          type="button"
          label="Submit"
          (click)="submit()"
          class="p-button-outlined"
        ></button>
      </div>
    </div>
  </div>
  <p-dialog
    header="Upload Documents"
    [(visible)]="itemDocumentsBool"
    [style]="{ width: '90vw' }"
  >
    <div class="flex flex-row justify-content-between pt-1" *ngIf="!viewItem">
      <div *ngFor="let doc of itemDocumentTypes">
        <p-fileUpload
          [multiple]="true"
          [auto]="true"
          maxFileSize="10000000"
          (onSelect)="onSelectedFile($event, doc)"
          mode="basic"
          chooseLabel="{{ doc.documentTyepe }}"
        >
        </p-fileUpload>
        <div *ngIf="doc.FileNames && doc.FileNames.length > 0">
          <div
            *ngFor="let fileName of doc.FileNames"
            class="flex flex-row align-items-center"
          >
            <p class="mb-1 files-font">
              {{ fileName }}
            </p>
            <button
              pButton
              pRipple
              icon="pi pi-times"
              (click)="removeFile(doc, fileName)"
              class="p-button-rounded p-button-text"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>

  <p-galleria
    [(value)]="images"
    [(visible)]="imageDisplay"
    [responsiveOptions]="responsiveOptions"
    [containerStyle]="{ 'max-width': '850px' }"
    [numVisible]="7"
    [circular]="true"
    [fullScreen]="true"
    [showItemNavigators]="true"
    [showThumbnails]="false"
    [baseZIndex]="100000"
  >
    <ng-template pTemplate="item" let-item>
      <img
        [src]="imageBaseUrl + 'resources/images/' + item.name"
        style="width: 100%; display: block"
      />
    </ng-template>
    <ng-template pTemplate="thumbnail" let-item>
      <div class="p-grid p-nogutter p-justify-center">
        <img
          [src]="imageBaseUrl + 'resources/images/' + item.name"
          style="display: block"
        />
      </div>
    </ng-template>
  </p-galleria>
</form>
