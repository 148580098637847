<section>
  <p>
    Are you sure you want to delete Item <b>{{ item.itemCode }}</b>
  </p>
  <div class="flex justify-content-end">
    <button
      pButton
      pRipple
      label="Yes"
      (click)="deleteItem(item.itemId)"
      class="p-button-outlined p-button-danger"
    ></button>
    <button
      pButton
      pRipple
      label="No"
      (click)="cancelDelete()"
      class="p-button-outlined p-button-secondary ml-3"
    ></button>
  </div>
</section>
