<div #headerMenu class="header-container" *ngIf="showHeader == true">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"
      ><img
        style="max-width: 100%"
        src="../assets/images/Apollo-Logo.png"
    /></a>
  </div>
</div>

<div class="header-container" *ngIf="showHeader == true">
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="user?.name"
        [picture]="user?.picture"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
