import {
  createFeatureSelector,
  createSelector,
  DefaultProjectorFn,
  MemoizedSelector,
} from '@ngrx/store';
import { AuthResponse } from '../shared/models/auth-response.model';
import { AuthState } from './auth.state';

export const selectAuthFeature: MemoizedSelector<
  object,
  AuthState,
  DefaultProjectorFn<AuthState>
> = createFeatureSelector<AuthState>('auth');

export const selectAuthPending: MemoizedSelector<
  object,
  boolean,
  DefaultProjectorFn<boolean>
> = createSelector(selectAuthFeature, (state: AuthState) => state.pending);

export const selectAuthUser: MemoizedSelector<
  object,
  AuthResponse | null,
  DefaultProjectorFn<AuthResponse | null>
> = createSelector(selectAuthFeature, (state: AuthState) => state.user);

export const selectAuthError = createSelector(
  selectAuthFeature,
  (authState: AuthState) => authState.error
);
