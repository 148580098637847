<p-card>
  <div
    class="px-5 min-h-screen flex justify-content-center align-items-center background"
  >
    <p-progressSpinner *ngIf="loading"></p-progressSpinner>
    <form [formGroup]="signInFormGroup" *ngIf="!loading">
      <p-messages [value]="messages"></p-messages>
      <div
        class="border-1 surface-border surface-card border-round py-5 px-4 md:px-7"
      >
        <div class="flex justify-content-center">
          <img
            src="favicon.ico"
            alt="Logo"
            class="flex align-self-center"
            height="40"
          />
          <h1 class="my-0">POLLO</h1>
        </div>
        <div class="mb-4">
          <div class="text-900 text-xl font-bold mb-2">Log in</div>
          <span class="text-600 font-medium">Please enter your details</span>
        </div>
        <div class="flex flex-column">
          <span class="p-input-icon-left w-full mb-4">
            <i class="pi pi-user"></i>
            <input
              type="text"
              pinputtext
              placeholder="Username"
              class="p-inputtext p-component p-element w-full md:w-25rem"
              [formControlName]="fieldNames.Username"
            />
          </span>
          <span class="p-input-icon-left w-full mb-4">
            <i class="pi pi-lock"></i>
            <input
              type="password"
              pinputtext
              placeholder="Password"
              class="p-inputtext p-component p-element w-full md:w-25rem"
              [formControlName]="fieldNames.Password"
            />
          </span>
          <!-- <div class="mb-4 flex flex-wrap">
            <a
              class="text-600 cursor-pointer hover:text-primary cursor-pointer ml-auto transition-colors transition-duration-300"
              [routerLink]="['/reset-password']"
            >
              Reset password
            </a>
          </div> -->
          <button
            pbutton
            pripple
            (click)="submitForm()"
            label="Log In"
            class="p-element w-full p-button p-component"
          >
            <span class="p-button-label">Log In</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</p-card>
