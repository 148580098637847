<div class="px-5 min-h-screen flex justify-content-center align-items-center background">
  <form [formGroup]="userForm">
  <p-messages></p-messages>
  <div class="border-1 surface-border surface-card border-round py-7 px-4 md:px-7 z-1">
    <div class="mb-4">
      <div class="text-900 text-xl font-bold mb-2">Forgot Password</div>
      <span class="text-600 font-medium">
        Enter your Username to reset your password.
      </span>
    </div>
    <div class="flex flex-column">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-user"></i>
        <input type="text" pinputtext placeholder="Username" formControlName="username"
          class="p-inputtext p-component p-element w-full md:w-25rem"/>
      </span>
      <p class="text-xs mb-4">You will be sent an Email To Reset Your Password</p>
      <div class="flex flex-wrap gap-2 justify-content-between">
        <button pButton pripple label="Cancel" [routerLink]="['/login']"
          class="p-element flex-auto p-button-outlined p-button p-component">
        </button>
        <button pButton pRipple label="Submit" (click)="checkUsername()"
          class="p-element flex-auto p-button p-component">
        </button>
      </div>
    </div>
  </div>
</form>
</div>
