<section class="flex flex-column">
  <div class="flex flex-row gap-3">
    <div *ngFor="let category of docTypes" class="p-field-checkbox">
      <p-checkbox
        value="category"
        [value]="category"
        (onChange)="onCheckboxChange($event, category)"
        [inputId]="category.companyDocumentTypeId"
        [disabled]="category.exists == false"
      ></p-checkbox>
      <label [for]="category.companyDocumentTypeId">
        {{ category.folderName }}
      </label>
    </div>
  </div>

  <button
    pButton
    pRipple
    type="button"
    label="Download Documents"
    (click)="downloadDocs()"
    class="p-button-outlined mt-4 p-2 w-5 flex align-self-center"
  ></button>
</section>
