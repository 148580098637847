import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  private branchesSubject = new BehaviorSubject<any[]>([]);
  private salesmanSubject = new BehaviorSubject<any[]>([]);
  private clientsSubject = new BehaviorSubject<any[]>([]);
  private statusesSubject = new BehaviorSubject<any[]>([]);
  private countriesSubject = new BehaviorSubject<any[]>([]);
  private stagesSubject = new BehaviorSubject<any[]>([]);
  private timeFramesSubject = new BehaviorSubject<any[]>([]);
  private currenciesSubject = new BehaviorSubject<any[]>([]);
  private projectsSubject = new BehaviorSubject<any[]>([]);
  private designersSubject = new BehaviorSubject<any[]>([]);
  private sourcesSubject = new BehaviorSubject<any[]>([]);
  private housingSubject = new BehaviorSubject<any[]>([]);
  private ipCodeSubject = new BehaviorSubject<any[]>([]);
  private shapeSubject = new BehaviorSubject<any[]>([]);
  private installationSubject = new BehaviorSubject<any[]>([]);
  private subCategorySubject = new BehaviorSubject<any[]>([]);
  private suppliersSubject = new BehaviorSubject<any[]>([]);
  private allUsersSubject = new BehaviorSubject<any[]>([]);
  private allProjectsSubject = new BehaviorSubject<any[]>([]);
  private clientSubTypesSubject = new BehaviorSubject<any[]>([]);
  private ksaAreasSubject = new BehaviorSubject<any[]>([]);

  branches$ = this.branchesSubject.asObservable();
  salesman$ = this.salesmanSubject.asObservable();
  clients$ = this.clientsSubject.asObservable();
  statuses$ = this.statusesSubject.asObservable();
  countries$ = this.countriesSubject.asObservable();
  stages$ = this.stagesSubject.asObservable();
  timeFrames$ = this.timeFramesSubject.asObservable();
  currencies$ = this.currenciesSubject.asObservable();
  projects$ = this.projectsSubject.asObservable();
  designers$ = this.designersSubject.asObservable();
  sources$ = this.sourcesSubject.asObservable();
  housing$ = this.housingSubject.asObservable();
  ipCode$ = this.ipCodeSubject.asObservable();
  shape$ = this.shapeSubject.asObservable();
  installation$ = this.installationSubject.asObservable();
  subCategory$ = this.subCategorySubject.asObservable();
  suppliers$ = this.suppliersSubject.asObservable();
  allUsers$ = this.allUsersSubject.asObservable();
  allProjects$ = this.allProjectsSubject.asObservable();
  clientSubTypes$ = this.clientSubTypesSubject.asObservable();
  ksaAreasSubject$ = this.ksaAreasSubject.asObservable();

  constructor(private readonly http: HttpClient) {}

  getBranches(): Observable<any[]> {
    if (this.branchesSubject.getValue().length === 0) {
      this.fetchBranches().subscribe((data) => this.setBranches(data));
    }
    return this.branches$;
  }

  private fetchBranches(): Observable<any[]> {
    return this.http.get<any[]>('Branches/GetAllBranches');
  }

  getClients(): Observable<any[]> {
    if (this.clientsSubject.getValue().length === 0) {
      this.fetchClients().subscribe((data) => this.setClients(data));
    }
    return this.clients$;
  }

  private fetchClients(): Observable<any[]> {
    return this.http.get<any[]>('company/GetAllClients');
  }

  getCountries(): Observable<any> {
    if (this.countriesSubject.getValue().length === 0) {
      this.fetchCountries().subscribe((data) => this.setCountries(data));
    }
    return this.countries$;
  }

  private fetchCountries(): Observable<any> {
    return this.http.get<any>('Settings/GetAllCountriesWithValidParameters');
  }

  getStatuses(): Observable<any> {
    if (this.statusesSubject.getValue().length === 0) {
      this.fetchStatuses().subscribe((data) => this.setStatuses(data));
    }
    return this.statuses$;
  }

  private fetchStatuses(): Observable<any> {
    return this.http.get<any>('company/GetAllBasicsByCategoryId/' + 8);
  }

  getStages(): Observable<any> {
    if (this.stagesSubject.getValue().length === 0) {
      this.fetchStages().subscribe((data) => this.setStages(data));
    }
    return this.stages$;
  }

  private fetchStages(): Observable<any> {
    return this.http.get<any>('company/GetAllBasicsByCategoryId/' + 7);
  }

  getTimeFrames(): Observable<any> {
    if (this.timeFramesSubject.getValue().length === 0) {
      this.fetchTimeFrames().subscribe((data) => this.setTimeFrames(data));
    }
    return this.timeFrames$;
  }

  private fetchTimeFrames(): Observable<any> {
    return this.http.get<any>('company/GetAllBasicsByCategoryId/' + 9);
  }

  getSalesman(): Observable<any> {
    if (this.salesmanSubject.getValue().length === 0) {
      this.fetchSalesman().subscribe((data) => this.setSalesman(data));
    }
    return this.salesman$;
  }

  private fetchSalesman(): Observable<any> {
    return this.http.get<any>('User/GetSalesAndDesignersAndCEO');
  }

  getCurrencies(): Observable<any> {
    if (this.currenciesSubject.getValue().length === 0) {
      this.fetchCurrencies().subscribe((data) => this.setCurrencies(data));
    }
    return this.currencies$;
  }

  private fetchCurrencies(): Observable<any> {
    return this.http.get<any>('Payment/GetAllCurrency');
  }

  getProjects(): Observable<any> {
    if (this.projectsSubject.getValue().length === 0) {
      this.fetchProjects().subscribe((data) => this.setProjects(data));
    }
    return this.projects$;
  }

  private fetchProjects(): Observable<any> {
    return this.http.get<any>('Project/GetAllProjectNames');
  }

  getDesigners(): Observable<any> {
    if (this.designersSubject.getValue().length === 0) {
      this.fetchDesigners().subscribe((data) => this.setDesigners(data));
    }
    return this.designers$;
  }

  private fetchDesigners(): Observable<any> {
    return this.http.get<any>('User/GetUsersByRoleID/' + 6);
  }

  getSources(): Observable<any> {
    if (this.sourcesSubject.getValue().length === 0) {
      this.fetchSources().subscribe((data) => this.setSources(data));
    }
    return this.sources$;
  }

  private fetchSources(): Observable<any> {
    return this.http.get<any>('company/GetAllBasicsByCategoryId/' + 13);
  }

  getHousing(): Observable<any> {
    if (this.housingSubject.getValue().length === 0) {
      this.fetchHousing().subscribe((data) => this.setHousing(data));
    }
    return this.housing$;
  }

  private fetchHousing(): Observable<any> {
    return this.http.get<any>('Item/GetAllSpecsByCategoryId/' + 1);
  }

  getIpCode(): Observable<any> {
    if (this.ipCodeSubject.getValue().length === 0) {
      this.fetchIpCode().subscribe((data) => this.setIpCode(data));
    }
    return this.ipCode$;
  }

  private fetchIpCode(): Observable<any> {
    return this.http.get<any>('Item/GetAllSpecsByCategoryId/' + 6);
  }

  getShape(): Observable<any> {
    if (this.shapeSubject.getValue().length === 0) {
      this.fetchShape().subscribe((data) => this.setShape(data));
    }
    return this.shape$;
  }

  private fetchShape(): Observable<any> {
    return this.http.get<any>('Item/GetAllSpecsByCategoryId/' + 9);
  }

  getInstallation(): Observable<any> {
    if (this.installationSubject.getValue().length === 0) {
      this.fetchInstallation().subscribe((data) => this.setInstallation(data));
    }
    return this.installation$;
  }

  private fetchInstallation(): Observable<any> {
    return this.http.get<any>('Item/GetAllSpecsByCategoryId/' + 17);
  }

  getSubCategory(): Observable<any> {
    if (this.subCategorySubject.getValue().length === 0) {
      this.fetchSubCategory().subscribe((data) => this.setSubCategory(data));
    }
    return this.subCategory$;
  }

  private fetchSubCategory(): Observable<any> {
    return this.http.get<any>('Item/GetAllSpecsByCategoryId/' + 16);
  }

  getSuppliers(): Observable<any> {
    if (this.suppliersSubject.getValue().length === 0) {
      this.fetchSuppliers().subscribe((data) => this.setSuppliers(data));
    }
    return this.suppliers$;
  }

  private fetchSuppliers(): Observable<any> {
    return this.http.get<any>('company/GetAllCompaniesByCompanyTypeId/' + 2);
  }

  getAllUsers(): Observable<any> {
    if (this.allUsersSubject.getValue().length === 0) {
      this.fetchAllUsers().subscribe((data) => this.setAllUsers(data));
    }
    return this.allUsers$;
  }

  private fetchAllUsers(): Observable<any> {
    return this.http.get<any>('User/GetAllUsers');
  }

  getAllProjects(): Observable<any> {
    if (this.allProjectsSubject.getValue().length === 0) {
      this.fetchAllProjects().subscribe((data) => this.setAllProjects(data));
    }
    return this.allProjects$;
  }

  private fetchAllProjects(): Observable<any> {
    return this.http.get<any>('Project/GetAllDropDownProjects');
  }

  getClientSubTypes(): Observable<any> {
    if (this.clientSubTypesSubject.getValue().length === 0) {
      const clientId = 1;
      this.fetchClientSubTypes(clientId).subscribe((data) =>
        this.setClientSubTypes(data)
      );
    }
    return this.clientSubTypes$;
  }

  private fetchClientSubTypes(clientId: number): Observable<any> {
    return this.http.get<any>(
      'company/GetCompanySubTypesByCompanyTypeId/' + clientId
    );
  }

  getKsaAreas(): Observable<any> {
    if (this.ksaAreasSubject.getValue().length === 0) {
      this.fetchKsaAreas().subscribe((data) => this.setKsaAreas(data));
    }
    return this.ksaAreasSubject$;
  }

  private fetchKsaAreas(): Observable<any> {
    return this.http.get<any>('company/getAllBasicsByCategoryId/' + 12);
  }

  setSalesman(data: any[]) {
    this.salesmanSubject.next(data);
  }

  setClients(data: any[]) {
    this.clientsSubject.next(data);
  }

  setBranches(data: any[]) {
    this.branchesSubject.next(data);
  }

  setStatuses(data: any[]) {
    this.statusesSubject.next(data);
  }

  setCountries(data: any[]) {
    this.countriesSubject.next(data);
  }

  setStages(data: any[]) {
    this.stagesSubject.next(data);
  }

  setTimeFrames(data: any[]) {
    this.timeFramesSubject.next(data);
  }

  setCurrencies(data: any[]) {
    this.currenciesSubject.next(data);
  }

  setProjects(data: any[]) {
    this.projectsSubject.next(data);
  }

  setDesigners(data: any[]) {
    this.designersSubject.next(data);
  }

  setSources(data: any[]) {
    this.sourcesSubject.next(data);
  }

  setHousing(data: any[]) {
    this.housingSubject.next(data);
  }

  setIpCode(data: any[]) {
    this.ipCodeSubject.next(data);
  }

  setShape(data: any[]) {
    this.shapeSubject.next(data);
  }

  setInstallation(data: any[]) {
    this.installationSubject.next(data);
  }

  setSubCategory(data: any[]) {
    this.subCategorySubject.next(data);
  }

  setSuppliers(data: any[]) {
    this.suppliersSubject.next(data);
  }

  setAllUsers(data: any[]) {
    this.allUsersSubject.next(data);
  }

  setAllProjects(data: any[]) {
    this.allProjectsSubject.next(data);
  }

  setClientSubTypes(data: any[]) {
    this.clientSubTypesSubject.next(data);
  }

  setKsaAreas(data: any[]) {
    this.ksaAreasSubject.next(data);
  }
}
