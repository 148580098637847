import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { StorageMap } from '@ngx-pwa/local-storage';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { Observable, Subject, forkJoin, takeUntil } from 'rxjs';
import { AddContactComponent } from '../../../contacts/dialogues/add-contact/add-contact.component';
import {
  Company,
  CompanySubType,
  CompanyType,
} from '../../shared/models/company.model';
import { Contact } from '../../shared/models/contact.model';

@Component({
  selector: 'ngx-new-company',
  templateUrl: './new-company.component.html',
  styleUrls: ['./new-company.component.scss'],
})
export class NewCompanyComponent implements OnInit, OnDestroy {
  addCompanyForm!: UntypedFormGroup;
  newContacts: Contact[] = [];
  company: Company;
  viewCompany = false;

  ksaAreas: any[] = [];

  private destroy$ = new Subject<void>();

  companyDocumentsBool: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public ref: DynamicDialogRef,
    private readonly http: HttpClient,
    public dialogService: DialogService,
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    private storage: StorageMap
  ) {}

  ngOnInit(): void {
    this.company = this.dynamicDialogConfig.data[0];
    this.viewCompany = this.dynamicDialogConfig.data[1] === 'view';
    this.addCompanyForm = this.formBuilder.group({
      companyId: [this.company.companyId || 0],
      companyTypeId: [this.company.companyTypeId || null, Validators.required],
      companySubTypeId: [
        this.company.companySubTypeId || null,
        Validators.required,
      ],
      companyName: [this.company.companyName || null, Validators.required],
      areaId: [this.company.areaId || null],
      abbreviation: [this.company.abbreviation || null],
      countryOfOriginId: [
        this.company.countryOfOriginId || null,
        Validators.required,
      ],
      regionalCountryId: [this.company.regionalCountryId || null],
      phoneOne: [this.company.phoneOne || null, Validators.required],
      phoneTwo: [this.company.phoneTwo || null],
      phoneThree: [this.company.phoneThree || null],
      faxOne: [this.company.faxOne || null],
      faxTwo: [this.company.faxTwo || null],
      email: [this.company.email || null, Validators.email],
      pobox: [this.company.pobox || null],
      website: [this.company.website || null],
      city: [this.company.city || null],
      area: [this.company.area || null],
      address: [this.company.address || null],
      classificationId: [this.company.classificationId || null],
      paymentModeId: [this.company.paymentModeId || null],
      paymentPeriodId: [this.company.paymentPeriodId || null],
      discount: [this.company.discount || null],
      defaultCurrencyId: [
        this.company.defaultCurrencyId || null,
        Validators.required,
      ],
      secondCurrencyId: [this.company.secondCurrencyId || null],
      thirdCurrencyId: [this.company.thirdCurrencyId || null],
      latitude: [this.company.latitude || null],
      longitude: [this.company.longitude || null],
      remarks: [this.company.remarks || null],
      createdByUserId: [this.company.createdByUserId || null],
      updatedByUserId: [this.company.updatedByUserId || null],
      newContacts: [this.company.contacts || []],
    });
    this.GetCompanyDocumentTypes();
    this.GetCompanyTypes().subscribe((types) => {
      this.companyType = types;
    });

    this.addCompanyForm
      .get('companyTypeId')
      ?.valueChanges.subscribe((value) => {
        this.GetCompanySubTypesByCompanyTypeId(value).subscribe(
          (companySubTypess: CompanySubType[]) => {
            this.companySubTypes = companySubTypess;
          }
        );
        this.getAllCompanies();
      });

    if (this.company.regionalCountryId == 218) {
      this.regionalChosen = true;
    }

    forkJoin({
      classification: this.getAllBasicsByCategoryId(1),
      ksaAreas: this.getAllBasicsByCategoryId(12),
      paymentMethod: this.getAllBasicsByCategoryId(2),
      paymentPeriod: this.getAllBasicsByCategoryId(3),
      currency: this.getAllCurrency(),
      country: this.getAllCountries(),
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe((results) => {
        this.classification = results.classification;
        this.ksaAreas = results.ksaAreas;
        this.paymentMethod = results.paymentMethod;
        this.paymentPeriod = results.paymentPeriod;
        this.currency = results.currency;
        this.country = results.country;
      });

    if (this.company.companyTypeId) {
      this.GetCompanySubTypesByCompanyTypeId(
        this.company.companyTypeId
      ).subscribe((companySubTypess: CompanySubType[]) => {
        this.companySubTypes = companySubTypess;
      });
      this.getAllCompanies();
    }
    this.addCompanyForm
      .get('countryOfOriginId')
      ?.valueChanges.subscribe((value) => {
        if (value) {
          this.GetCountryExtById(value);
        }
      });

    this.getUserId();
    this.getSalesman();
    this.getPosition();
  }

  isSubmitting: boolean = false;
  onSubmit(addCompanyForm) {
    if (addCompanyForm.valid) {
      this.isSubmitting = true;
      this.http
        .post<any>('company/SubmitCompanyWithContacts', addCompanyForm.value)
        .subscribe({
          next: (data) => {
            this.uploadFiles(data.companyId);
            this.isSubmitting = false;
            this.ref.close(true);
            this.isSubmitting = false;
            this.ref.close(false);
          },
          error: () => {
            this.isSubmitting = false;

            this.ref.close(false);
          },
        });
    } else {
      alert('Please fill all required fields');
    }
  }

  companies: any[] = [];
  getAllCompanies() {
    this.http
      .get<any>(
        'company/GetAllCompaniesDropDownByCompanyTypeId/' +
          this.addCompanyForm.get('companyTypeId')?.value
      )
      .subscribe((companyInfo) => {
        this.companies = companyInfo;
      });
  }

  filteredCompanies: any[] = [];
  filterCompany(event: any) {
    let query = event.query;
    this.filteredCompanies = this.companies.filter((company) =>
      company.companyName.toLowerCase().includes(query.toLowerCase())
    );
  }

  onCompanySelect(event: any) {
    this.addCompanyForm.patchValue({
      companyName: event.companyName,
    });
  }

  regionalChosen: boolean = false;
  onRegionalCountryChange(event: any) {
    if (event.value == 218) {
      this.regionalChosen = true;
    } else {
      this.regionalChosen = false;
    }
  }

  showAddContact() {
    this.dialogService
      .open(AddContactComponent, {
        data: [
          this.newContacts,
          this.addCompanyForm.get('companyName')?.value,
          'company',
        ],
        header: 'Add Contact',
        width: '80%',
      })
      .onClose.subscribe((newContact: Contact) => {
        if (newContact) {
          const ab = this.addCompanyForm.get('newContacts');
          ab?.value.push(newContact);
          this.addCompanyForm.get('newContacts')?.setValue(ab?.value);
        }
      });
  }

  companyTypesForm: UntypedFormGroup;
  companyType: CompanyType[] = [];
  GetCompanyTypes() {
    return this.http.get<any>('company/GetCompanyTypes');
  }

  companySubTypes: CompanySubType[] = [];
  GetCompanySubTypesByCompanyTypeId(ComapanyTypeId: any) {
    return this.http.get<any>(
      'company/GetCompanySubTypesByCompanyTypeId/' + ComapanyTypeId
    );
  }

  classification: any[] = [];
  paymentMethod: any[] = [];
  paymentPeriod: any[] = [];
  getAllBasicsByCategoryId(BasicCategoryid: any): Observable<any> {
    return this.http.get<any>(
      'company/getAllBasicsByCategoryId/' + BasicCategoryid
    );
  }

  currency: any[] = [];
  getAllCurrency(): Observable<any> {
    return this.http.get<any>('Payment/getAllCurrency');
  }

  country: any[] = [];
  getAllCountries(): Observable<any> {
    return this.http.get<any>('Settings/getAllCountries');
  }
  companyDocsTypes: any[] = [];
  GetCompanyDocumentTypes() {
    this.http.get<any>('company/GetCompanyDocumentTypes').subscribe((res) => {
      res.forEach((element) => {
        let type = {
          ...element,
          FileName: '',
          File: null,
        };
        this.companyDocsTypes.push(type);
      });
    });
  }

  showEditContact(contact: Contact) {
    this.dialogService
      .open(AddContactComponent, {
        data: [contact, this.addCompanyForm.get('companyName')?.value],
        header: 'Edit Contact ID: ' + contact.contactId,
        width: '80%',
      })
      .onClose.subscribe((updatedContact: Contact) => {
        if (updatedContact) {
          const formArray = this.addCompanyForm.get(
            'newContacts'
          ) as UntypedFormArray;
          const index = formArray.controls.findIndex(
            (x) => x.value.contactId === updatedContact.contactId
          );
          formArray.controls[index].setValue(updatedContact);
        }
      });
  }

  uploadFiles(companyId: number) {
    this.companyDocsTypes.forEach((doc) => {
      if (doc.Files != null) {
        doc.Files.forEach((File) => {
          const formData = new FormData();
          formData.append('file', File, File.name);
          this.http
            .post(
              'Item/UploadCompanyDoc/' +
                companyId +
                '/' +
                doc.companyDocumentTypeId +
                '/' +
                20,
              formData,
              { reportProgress: true, observe: 'events' }
            )
            .subscribe();
        });
      }
    });
  }

  GetCountryExtById(countryId: any) {
    this.http
      .get<any>('Settings/GetCountryById/' + countryId)
      .subscribe((data) => {
        const landLineExt = data.landLineExt;
        if (landLineExt == null || landLineExt == 'null') {
          return;
        }
        this.addCompanyForm.get('phoneOne')?.patchValue(parseInt(landLineExt));
        this.addCompanyForm.get('phoneTwo')?.patchValue(parseInt(landLineExt));
        this.addCompanyForm
          .get('phoneThree')
          ?.patchValue(parseInt(landLineExt));
      });
  }

  onSelectedFile(event: any, doc: any) {
    if (event.files && event.files.length > 0) {
      if (!doc.Files) {
        doc.Files = [];
        doc.FileNames = [];
      }

      for (const file of event.files) {
        doc.Files.push(file);
        doc.FileNames.push(file.name);
      }
    }
  }

  Identity: any;
  ActiveUserId: any;
  getUserId() {
    this.storage.get('Identity').subscribe((user) => {
      this.Identity = user;
      this.ActiveUserId = this.Identity.userId;
      if (this.addCompanyForm.get('createdByUserId')?.value == null) {
        this.addCompanyForm
          .get('createdByUserId')
          ?.patchValue(this.ActiveUserId);
      }
      this.addCompanyForm.get('updatedByUserId')?.patchValue(this.ActiveUserId);
    });
  }

  showViewContact(contact: Contact) {
    this.dialogService.open(AddContactComponent, {
      data: [
        contact,
        this.addCompanyForm.get('companyName')?.value,
        null,
        true,
      ],
      header: 'View Contact: ' + contact.firstName,
      width: '70%',
    });
  }

  showAddDocuments() {
    this.companyDocumentsBool = !this.companyDocumentsBool;
  }

  removeFile(doc, fileName) {
    const index = doc.FileNames.indexOf(fileName);
    if (index > -1) {
      doc.FileNames.splice(index, 1);
      doc.Files.splice(index, 1);
    }
  }

  salesman: any[] = [];
  getSalesman() {
    this.http.get<any>('User/GetSalesAndDesignersAndCEO').subscribe((res) => {
      this.salesman = res;
    });
  }

  position: any[] = [];
  getPosition() {
    this.http
      .get<any>('company/getAllBasicsByCategoryId/' + 5)
      .subscribe((data) => {
        this.position = data;
      });
  }

  getSalesmanName(salesmanId: number) {
    const salesman = this.salesman.find((s) => s.userId === salesmanId);
    return salesman?.userName;
  }

  getPositionName(positionId: number) {
    const position = this.position.find((s) => s.basicId === positionId);
    return position?.value;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
