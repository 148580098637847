import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
    DynamicDialogConfig,
    DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { Contact } from '../../../companies/shared/models/contact.model';

@Component({
  selector: 'ngx-delete-contact',
  templateUrl: './delete-contact.component.html',
  styleUrls: ['./delete-contact.component.scss'],
})
export class DeleteContactComponent implements OnInit {
  contact: Contact;

  constructor(
    private readonly http: HttpClient,
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.contact = this.dynamicDialogConfig.data;
  }

  deleteContact(ContactId: any) {
    this.http.delete<any>('contact/DeleteContact/' + ContactId).subscribe();
    this.ref.close();
  }
  cancelDelete() {
    this.ref.close();
  }
}
