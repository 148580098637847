import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AuthenticateService } from '../../../shared/authenticate.service';
import { Company } from '../../shared/models/company.model';

@Component({
  selector: 'ngx-company-docs',
  templateUrl: './company-docs.component.html',
  styleUrls: ['./company-docs.component.scss'],
})
export class CompanyDocsComponent implements OnInit {
  company: Company;

  constructor(
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    private readonly http: HttpClient,
    private authservice: AuthenticateService
  ) {}

  ngOnInit(): void {
    this.company = this.dynamicDialogConfig.data;
    this.getDownloadableDocs(this.company.companyId);
  }

  docTypes: any = [];
  AllDocTypes: any;
  getDownloadableDocs(ComapanyId: any) {
    this.http
      .get<any>('company/getAllDownloadAbleCompanyDocs/' + ComapanyId)
      .subscribe((data) => {
        this.AllDocTypes = data;
        data.forEach((element) => {
          let doc = {
            ...element,
            selectedDocType: false,
          };
          this.docTypes.push(doc);
        });
      });
  }

  async downloadDocs() {
    const obj = {
      companyId: this.company.companyId,
      userId: await this.authservice.getUserId(),
      selectedDocumentTypes: this.selectedCategories,
    };
    this.selectedCategories.forEach((element) => {
      this.http
        .post('Item/DownloadCompanyDoc', obj, { responseType: 'arraybuffer' })
        .subscribe((data) => {
          this.saveFileAsZip(data, 'Documents.zip');
        });
    });
  }

  selectedCategories: any[] = [];

  onCheckboxChange(event: any, category: any) {
    if (event.checked) {
      this.selectedCategories.push(category);
    } else {
      this.selectedCategories = this.selectedCategories.filter(
        (item) => item !== category
      );
    }
  }

  saveFileAsZip(data: ArrayBuffer, filename: string) {
    const blob = new Blob([data], { type: 'application/zip' });

    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }
}
