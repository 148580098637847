import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { AuthRoutes, ModuleRoutes } from './enums/routes.enum';
import { TokenKeys } from './enums/tokens.enum';
import { JwtData } from './models/jwt-data.model';

@Injectable({
  providedIn: 'root',
})
export class AccessTokenService {
  public accessTokenData: JwtData | undefined = undefined;
  public authToken: string | undefined = undefined;

  private tokenSetSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly cookieService: CookieService,
    private readonly router: Router
  ) {}

  public isLoggedIn(): boolean {
    return !!this.getAccessToken();
  }

  public decodeAccessToken(accessToken: string): void {
    const decodedAccessToken: any = jwtDecode(accessToken);
    this.accessTokenData = {
      profile: decodedAccessToken,
      expiryDate: moment.unix(<number>decodedAccessToken.exp).toDate(),
    };
  }

  public setAccessToken(accessToken: string): void {
    this.cookieService.set(
      TokenKeys.JwtCookie,
      accessToken,
      this.accessTokenData?.expiryDate,
      '/'
    );
  }

  public setAuthToken(authToken: string): void {
    this.cookieService.set(
      TokenKeys.AuthToken,
      authToken,
      this.accessTokenData?.expiryDate,
      '/'
    );
    this.tokenSetSubject.next(true);
  }

  public tokenIsSetObservable(): Observable<boolean> {
    return this.tokenSetSubject
      .asObservable()
      .pipe(filter((isSet) => isSet === true));
  }

  public deleteAccessToken(): void {
    this.cookieService.delete(TokenKeys.JwtCookie);

    this.router.navigate([ModuleRoutes.Auth, AuthRoutes.SignIn]);
  }

  public getAccessToken(): string {
    return this.cookieService.get(TokenKeys.JwtCookie);
  }

  public getAuthToken(): string {
    return this.cookieService.get(TokenKeys.AuthToken);
  }
}
