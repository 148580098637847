import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Optional } from '@angular/core';
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { GetCommentsResponse } from '../../models/comment.model';

@Component({
  selector: 'ngx-create-comment',
  templateUrl: './create-comment.component.html',
  styleUrls: ['./create-comment.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ButtonModule,
    InputTextModule,
  ],
})
export class CreateCommentComponent implements OnInit {
  messageForm!: FormGroup;
  users: any[] = [];
  projects: any[] = [];
  currentMention: string = '';
  startedUserMention: boolean = false;
  startedProjectMention: boolean = false;
  filteredUsers: string[] = [];
  filteredProjects: any[] = [];
  text: string = '';
  previousKey: string = '';

  comments: GetCommentsResponse[] = [];
  @Input() entityTypeId: number = this.dynamicDialogConfig?.data?.entityTypeId;
  @Input() entityId: number = this.dynamicDialogConfig?.data?.entityId;
  @Input() userId: number = this.dynamicDialogConfig?.data?.userId;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly httpClient: HttpClient,
    @Optional() private readonly dynamicDialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.getUsers();
    this.getProjects();

    this.createForm();
    this.getComments();
  }

  createForm() {
    this.messageForm = this.formBuilder.group({
      entityId: this.entityId,
      userId: this.userId,
      entityTypeId: this.entityTypeId,
      commnent: '',
      mentionedUsersId: [[]],
      mentionedProjectsId: [[]],
    });
  }

  handleSelect(user, textareaRef: HTMLTextAreaElement) {
    this.startedUserMention = false;
    const atIndex = this.text.lastIndexOf('@');

    this.text = this.text.substring(0, atIndex + 1) + user.userName + ' ';

    const addedUsers = this.messageForm.get('mentionedUsersId')?.value;
    addedUsers.push(user.userId);
    this.messageForm.get('mentionedUsersId')?.patchValue(addedUsers);

    this.filteredUsers = [];
    textareaRef.focus();
  }

  handleSelectProject(project, textareaRef: HTMLTextAreaElement) {
    this.startedProjectMention = false;
    const hashIndex = this.text.lastIndexOf('#');

    this.text =
      this.text.substring(0, hashIndex + 1) + project.projectName + ' ';

    const addedProjects = this.messageForm.get('mentionedProjectsId')?.value;
    addedProjects.push(project.projectId);
    this.messageForm.get('mentionedProjectsId')?.patchValue(addedProjects);

    this.filteredProjects = [];
    textareaRef.focus();
  }

  addComment() {
    this.httpClient
      .post<Boolean>('item/AddComment', this.messageForm.value)
      .subscribe((res) => {
        if (res) {
          this.getComments();
          this.messageForm.reset();
        }
      });
  }

  MENTION_USERS = '@';
  MENTION_PROJECTS = '#';
  BACKSPACE = 'Backspace';

  handleKeyDown(event: any) {
    switch (event.key) {
      case this.MENTION_USERS:
        this.startUserMention();
        break;
      case this.MENTION_PROJECTS:
        this.startProjectMention();
        break;
      case this.BACKSPACE:
        this.handleBackspace();
        break;
      default:
        this.handleDefault(event.key);
    }
    this.previousKey = event.key;
  }

  startUserMention() {
    this.startedUserMention = true;
    this.startedProjectMention = false;
    this.resetMentionState();
    this.filteredUsers = [...this.users];
  }

  startProjectMention() {
    this.startedProjectMention = true;
    this.startedUserMention = false;
    this.resetMentionState();
    this.filteredProjects = [...this.projects];
  }

  resetMentionState() {
    this.currentMention = '';
    this.filteredUsers = [];
    this.filteredProjects = [];
  }

  handleBackspace() {
    if (this.startedUserMention || this.startedProjectMention) {
      this.processMentionBackspace();
    } else {
      this.checkForMentionRemoval();
      this.resetMentionState();
    }
  }

  processMentionBackspace() {
    if (
      this.previousKey === this.MENTION_USERS ||
      this.previousKey === this.MENTION_PROJECTS
    ) {
      this.resetMentionState();
      return;
    }

    if (this.currentMention.length > 0) {
      this.currentMention = this.currentMention.slice(0, -1);
    }

    if (this.startedUserMention) {
      this.filterUsers();
    } else if (this.startedProjectMention) {
      this.filterProjects();
    }
  }

  handleDefault(key: string) {
    if (key === ' ') {
      this.resetMentionState();
      return;
    }

    this.currentMention += key;
    if (this.startedUserMention) {
      this.filterUsers();
    } else if (this.startedProjectMention) {
      this.filterProjects();
    }
  }

  filterUsers() {
    this.filteredUsers = this.users.filter((user) =>
      user.userName.toLowerCase().includes(this.currentMention.toLowerCase())
    );
  }

  filterProjects() {
    this.filteredProjects = this.projects.filter(
      (project) =>
        project.projectName
          .toLowerCase()
          .includes(this.currentMention.toLowerCase()) ||
        project.projectId.toString().includes(this.currentMention.toLowerCase())
    );
  }

  checkForMentionRemoval() {
    const atIndex = this.text.lastIndexOf(this.MENTION_USERS);
    const hashIndex = this.text.lastIndexOf(this.MENTION_PROJECTS);
    const lastIndex = Math.max(atIndex, hashIndex);

    if (lastIndex !== -1) {
      const lastSpaceIndex = this.text.lastIndexOf(' ', lastIndex - 1);
      const mention = this.text.substring(lastSpaceIndex + 1, lastIndex);

      if (atIndex > hashIndex) {
        this.removeUserMention(mention);
      } else {
        this.removeProjectMention(mention);
      }
    }
  }

  removeUserMention(mention: string) {
    const user = this.users.find((u) => u.userName === mention);
    if (user) {
      const mentionedUsersId = this.messageForm.get('mentionedUsersId').value;
      const index = mentionedUsersId.indexOf(user.userId);
      if (index > -1) {
        mentionedUsersId.splice(index, 1);
        this.messageForm.get('mentionedUsersId').setValue(mentionedUsersId);
      }
    }
  }

  removeProjectMention(mention: string) {
    const project = this.projects.find((p) => p.projectName === mention);
    if (project) {
      const mentionedProjectsId = this.messageForm.get(
        'mentionedProjectsId'
      ).value;
      const index = mentionedProjectsId.indexOf(project.projectId);
      if (index > -1) {
        mentionedProjectsId.splice(index, 1);
        this.messageForm
          .get('mentionedProjectsId')
          .setValue(mentionedProjectsId);
      }
    }
  }

  getUsers() {
    this.httpClient.get<any>('User/GetAllActiveUsers').subscribe((data) => {
      this.users = data;
    });
  }

  getProjects() {
    this.httpClient
      .get<any>('Project/GetAllDropDownProjects')
      .subscribe((data) => {
        this.projects = data;
      });
  }

  getComments() {
    this.httpClient
      .get<GetCommentsResponse[]>(
        `project/GetComments/${this.userId}/${this.entityId}/${this.entityTypeId}`
      )
      .subscribe((data) => {
        this.comments = data;
      });
  }

  deleteComment(comment: GetCommentsResponse) {
    this.httpClient
      .delete(`Project/DeleteComment/${comment.commentId}`)
      .subscribe(() => {
        this.getComments();
      });
  }
}
