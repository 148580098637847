<form
  [formGroup]="addContactForm"
  class="flex flex-row justify-content-between mt-4 gap-3"
  [class.disabled-form]="isView"
>
  <div class="flex flex-row justify-content-center gap-2 w-4">
    <div class="flex flex-column gap-2 mt-2 height-fix">
      <p>Company Name</p>
      <p>Salutation</p>
      <p>First Name *</p>
      <p>Middle Name</p>
      <p>Last Name</p>
      <p>Professional Email</p>
    </div>
    <div class="flex flex-column gap-2 w-7">
      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="companies"
        [filter]="true"
        formControlName="companyId"
        [placeholder]="companyName"
        optionLabel="companyName"
        optionValue="companyId"
        filterBy="companyName"
        [showClear]="true"
      >
      </p-dropdown>

      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="salutation"
        formControlName="salutationId"
        placeholder=" "
        optionLabel="value"
        optionValue="basicId"
      >
      </p-dropdown>

      <p-autoComplete
        [style]="{ width: '100%', height: '2.5rem', 'font-size': '1.25em' }"
        formControlName="firstName"
        [suggestions]="fullName"
        (completeMethod)="filterContacts($event)"
        [class]="
          addContactForm.get('firstName').invalid &&
          addContactForm.get('firstName').touched
            ? 'ng-invalid ng-dirty'
            : ' '
        "
      ></p-autoComplete>
      <input type="text" pInputText formControlName="middleName" />
      <input type="text" pInputText formControlName="lastName" />
      <input type="text" pInputText formControlName="professionalEmail" />
    </div>
  </div>
  <div class="flex flex-row justify-content-center gap-2 w-4">
    <div class="flex flex-column gap-2 mt-2 height-fix">
      <p>Position</p>
      <p>Country of Origin</p>
      <p>Phone Number</p>
      <p>Phone Extension</p>
      <p>Mobile Number</p>
      <p>Other</p>
    </div>
    <div class="flex flex-column gap-2 w-7">
      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="position"
        formControlName="positionId"
        [filter]="true"
        filterBy="value"
        [showClear]="true"
        placeholder=" "
        optionLabel="value"
        optionValue="basicId"
      >
      </p-dropdown>

      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="country"
        formControlName="countryOfResidenceId"
        [filter]="true"
        filterBy="value"
        [showClear]="true"
        placeholder=" "
        optionLabel="title"
        optionValue="countryId"
      >
      </p-dropdown>

      <p-inputNumber
        formControlName="phone"
        mode="decimal"
        [useGrouping]="false"
      ></p-inputNumber>

      <p-inputNumber
        formControlName="ext"
        mode="decimal"
        [useGrouping]="false"
      ></p-inputNumber>

      <p-inputNumber
        formControlName="mobile"
        mode="decimal"
        [useGrouping]="false"
      ></p-inputNumber>

      <input type="text" pInputText formControlName="other" />
    </div>
  </div>

  <div class="flex flex-row justify-content-center gap-2 w-4">
    <div class="flex flex-column gap-2 mt-2 height-fix">
      <p>Skype</p>
      <p>Teams</p>
      <p>Zoom</p>
      <p>Linkedin</p>
      <p>Facebook</p>
      <p>Instagram</p>
    </div>
    <div class="flex flex-column gap-2 w-7">
      <input type="text" pInputText formControlName="skypeName" />
      <input type="text" pInputText formControlName="teams" />
      <input type="text" pInputText formControlName="zoom" />
      <input type="text" pInputText formControlName="linkedin" />
      <input type="text" pInputText formControlName="facebook" />
      <input type="text" pInputText formControlName="instagram" />
    </div>
  </div>

  <div class="flex flex-column w-2">
    <span class="flex flex-column">
      <p class="mb-0">Salesman *</p>
      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="salesman"
        formControlName="salesmanId"
        placeholder=" "
        optionLabel="userName"
        optionValue="userId"
      >
      </p-dropdown>
    </span>
    <span class="flex flex-column">
      <p class="mb-0">Remarks</p>
      <textarea
        rows="2"
        cols="10"
        pInputTextarea
        formControlName="remarks"
      ></textarea>
    </span>
    <span class="flex flex-row gap-2 mt-1">
      <p-fileUpload
        maxFileSize="10000000"
        accept="image/*"
        [auto]="true"
        mode="basic"
        (onSelect)="onImageUpload($event)"
        chooseLabel="Image"
      >
      </p-fileUpload>
      <img
        *ngIf="Image"
        [src]="getImageUrl()"
        alt="image"
        class="w-4 h-2 object-cover"
      />
    </span>
    <button
      *ngIf="!isView"
      pButton
      pRipple
      type="button"
      label="Submit"
      (click)="onSubmit()"
      class="p-button-outlined mt-2"
    ></button>
  </div>
</form>
