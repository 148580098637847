<form [formGroup]="addValueForm">
  <span class="p-float-label">
    <input pInputText formControlName="value" />
    <label>Add A Value</label>
  </span>
  <div class="flex justify-content-center mt-2">
    <button
      type="button"
      pButton
      pRipple
      label="Add"
      (click)="addItem()"
      class="p-button-outlined"
    ></button>
    <button
      type="button"
      pButton
      pRipple
      label="Cancel"
      (click)="cancelAdd()"
      class="p-button-outlined p-button-secondary ml-3"
    ></button>
  </div>
</form>
