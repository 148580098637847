import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbAccordionModule,
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { environment } from '../environments/environment';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { REDUCER_PROVIDER, getInitialState, reducerToken } from './app.store';
import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AuthRepository } from './auth/shared/auth.repository';
import { ModuleRoutes } from './auth/shared/enums/routes.enum';
import { AuthEffects } from './auth/store/auth.effects';
import { AuthFacade } from './auth/store/auth.facade';
import { authReducer } from './auth/store/auth.reducer';
import { CompanyDocsComponent } from './companies/dialogues/company-docs/company-docs.component';
import { DeleteCompanyComponent } from './companies/dialogues/delete-company/delete-company.component';
import { NewCompanyComponent } from './companies/dialogues/new-company/new-company.component';
import { ContactsComponent } from './contacts/contacts.component';
import { AddContactComponent } from './contacts/dialogues/add-contact/add-contact.component';
import { DeleteContactComponent } from './contacts/dialogues/delete-contact/delete-contact.component';
import { CustomHttpInterceptorComponent } from './custom-http-interceptor/custom-http-interceptor.component';
import { AddItemValueComponent } from './items/dialogues/add-item/add-item-value/add-item-value.component';
import { AddItemComponent } from './items/dialogues/add-item/add-item.component';
import { DeleteItemComponent } from './items/dialogues/delete-item/delete-item.component';
import { ItemDocsComponent } from './items/dialogues/item-docs/item-docs.component';
import { ViewItemComponent } from './items/dialogues/view-item/view-item.component';
import { AddInquiryComponent } from './projects/inquiry-list/add-inquiry/add-inquiry.component';
import { CalendarComponent } from './shared/components/calendar/calendar.component';
import { ViewColumnsComponent } from './shared/components/view-columns/view-columns.component';

@NgModule({
  declarations: [
    AppComponent,
    ViewColumnsComponent,
    NewCompanyComponent,
    DeleteCompanyComponent,
    AddContactComponent,
    LoginComponent,
    ResetPasswordComponent,
    DeleteContactComponent,
    AddItemComponent,
    ViewItemComponent,
    DeleteItemComponent,
    AddItemValueComponent,
    CompanyDocsComponent,
    ItemDocsComponent,
    AddInquiryComponent,
  ],
  imports: [
    ToastModule,
    ProgressSpinnerModule,
    CalendarComponent,
    OverlayPanelModule,
    CalendarModule,
    PowerBIEmbedModule,
    RadioButtonModule,
    DialogModule,
    CardModule,
    ChipModule,
    AutoCompleteModule,
    InputTextareaModule,
    InputNumberModule,
    GalleriaModule,
    TabMenuModule,
    BrowserModule,
    CommonModule,
    MessageModule,
    MessagesModule,
    CarouselModule,
    MenubarModule,
    PaginatorModule,
    DragDropModule,
    DataViewModule,
    FileUploadModule,
    ToggleButtonModule,
    DynamicDialogModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    InputMaskModule,
    DropdownModule,
    CheckboxModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbAccordionModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    StoreModule.forRoot(reducerToken, { initialState: getInitialState }),
    EffectsModule.forRoot([AuthEffects]),
    StoreModule.forFeature(ModuleRoutes.Auth, authReducer),
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    DialogService,
    AuthFacade,
    AuthRepository,
    REDUCER_PROVIDER,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptorComponent,
      multi: true,
    },
    ContactsComponent,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
