import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Item } from '../../shared/models/item.model';

@Component({
  selector: 'ngx-delete-item',
  templateUrl: './delete-item.component.html',
  styleUrls: ['./delete-item.component.scss'],
})
export class DeleteItemComponent implements OnInit {
  item: Item;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.item = this.dynamicDialogConfig.data;
  }
  deleteItem(itemId) {
    this.httpClient.delete<any>('Item/DeleteItem/' + itemId).subscribe(() => {
      this.ref.close();
    });
  }
  cancelDelete() {
    this.ref.close();
  }
}
