import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment.prod';
import { AnalyticsService } from './@core/utils/analytics.service';
import { SeoService } from './@core/utils/seo.service';

@Component({
  selector: 'app-root',
  template: '<p-toast></p-toast> <router-outlet> </router-outlet>',
})
export class AppComponent implements OnInit {
  environment = environment;

  constructor(
    private analytics: AnalyticsService,
    private seoService: SeoService
  ) {}

  ngOnInit(): void {
    this.analytics.trackPageViews();
    this.seoService.trackCanonicalChanges();
  }
}
