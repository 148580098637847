import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SignInDetails } from '../shared/models/auth-details.model';
import { AuthResponse } from '../shared/models/auth-response.model';
import { State } from '../shared/models/store.model';
import { signIn, signInFailure, signOut } from './auth.actions';
import {
  selectAuthError,
  selectAuthPending,
  selectAuthUser,
} from './auth.selectors';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  public selectAuthPending$: Observable<boolean> = this.store.pipe(
    select(selectAuthPending)
  );
  public selectAuthUser$: Observable<AuthResponse | null> = this.store.pipe(
    select(selectAuthUser)
  );

  public selectAuthError$: Observable<string | null> = this.store.pipe(
    select(selectAuthError)
  );

  constructor(private readonly store: Store<State>) {}

  public signIn(signInDetails: SignInDetails): void {
    this.store.dispatch(signIn({ signInDetails }));
  }

  public signOut(): void {
    this.store.dispatch(signOut());
  }

  public handleSignInFailure(errorMessage: string): void {
    this.store.dispatch(signInFailure({ error: errorMessage }));
  }
}
