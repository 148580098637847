import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageMap } from '@ngx-pwa/local-storage';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment.prod';
import { Contact } from '../../../companies/shared/models/contact.model';

export interface CompanyInfoForContact {
  id: number;
  name: string;
}

@Component({
  selector: 'ngx-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss'],
})
export class AddContactComponent implements OnInit, OnDestroy {
  addContactForm!: UntypedFormGroup;
  contact: Contact;
  companyName: string = '';
  addFromCompany: boolean = false;
  isView: boolean = false;

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private readonly httpClient: HttpClient,
    public ref: DynamicDialogRef,
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    private sanitizer: DomSanitizer,
    private storage: StorageMap
  ) {}

  ngOnInit(): void {
    this.contact = this.dynamicDialogConfig.data[0] || {};
    this.companyName = this.dynamicDialogConfig.data[1] || '';
    this.addFromCompany = this.dynamicDialogConfig.data[2] || false;
    this.isView = this.dynamicDialogConfig.data[3];

    this.addContactForm = this.formBuilder.group({
      contactId: [this.contact.contactId || 0],
      companyName: [this.contact.companyName || null],
      companyId: [this.contact.companyId || null],
      salutationId: [this.contact.salutationId || null],
      firstName: [this.contact.firstName || null, Validators.required],
      middleName: [this.contact.middleName || null],
      lastName: [this.contact.lastName || null],
      countryOfResidenceId: [this.contact.countryOfResidenceId || null],
      phone: [this.contact.phone || null],
      ext: [this.contact.ext || null],
      mobile: [this.contact.mobile || null],
      other: [this.contact.other || null],
      professionalEmail: [this.contact.professionalEmail || null],
      personalEmail: [this.contact.personalEmail || null],
      skypeName: [this.contact.skypeName || null],
      positionId: [this.contact.positionId || null],
      salesmanId: [this.contact.salesmanId || null, Validators.required],
      remarks: [this.contact.remarks || null],
      createdByUserId: [this.contact.createdByUserId || null],
      country: [this.contact.country] || null,
      positionTitle: [this.contact.positionTitle] || null,
      teams: [this.contact.teams] || null,
      salesmanName: [this.contact.salesmanName] || null,
      classification: [this.contact.classification] || null,
      ksaArea: [this.contact.ksaArea] || null,
      area: [this.contact.area] || null,
      instagram: [this.contact.instagram] || null,
      facebook: [this.contact.facebook] || null,
      linkedin: [this.contact.linkedin] || null,
      zoom: [this.contact.zoom] || null,
      twitter: [this.contact.twitter] || null,
      salutationTitle: [this.contact.salutationTitle] || null,
      mainContact: [this.contact.mainContact] || null,
    });

    this.getCompanies();
    this.getAllCountries()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.country = data;
      });
    this.getAllContactSearch();
    this.getPosition();
    this.getSalesman();
    this.getSalutation();

    this.getUserId();

    this.addContactForm
      .get('countryOfResidenceId')
      ?.valueChanges.subscribe((value) => {
        if (value) {
          this.getCountryExtById(value);
        }
      });
  }

  companies: any[] = [];
  getCompanies() {
    this.httpClient
      .get<any>('company/GetAllCompaniesDropDownByCompanyTypeId/' + 0)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.companies = data;
      });
  }

  salutation: any[] = [];
  getSalutation() {
    this.httpClient
      .get<any>('company/GetAllBasicsByCategoryId/' + 4)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.salutation = data;
      });
  }
  position: any[] = [];
  getPosition() {
    this.httpClient
      .get<any>('company/GetAllBasicsByCategoryId/' + 5)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.position = data;
      });
  }

  salesman: any[] = [];
  getSalesman() {
    this.httpClient
      .get<any>('User/GetSalesAndDesignersAndCEO')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.salesman = res;
      });
  }

  country: any[] = [];
  getAllCountries(): Observable<any> {
    return this.httpClient.get<any>('Settings/GetAllCountries');
  }

  Image: File;
  imageURL: string | null = null;

  onImageUpload(event) {
    if (event.currentFiles && event.currentFiles.length > 0) {
      this.Image = event.currentFiles[0];
      this.imageURL = URL.createObjectURL(this.Image);
    }
  }

  getImageUrl() {
    return this.imageURL
      ? this.sanitizer.bypassSecurityTrustUrl(this.imageURL)
      : null;
  }

  onSubmit() {
    if (this.addContactForm.valid) {
      if (this.addFromCompany) {
        const newContact = this.addContactForm.value;
        this.addContactForm.reset();
        this.addContactForm.patchValue({
          contactId: 0,
        });
        this.ref.close(newContact);
      } else {
        this.httpClient
          .post('Contact/SubmitContact', this.addContactForm.value)
          .subscribe((data: any) => {
            if (this.Image != null) {
              const formData = new FormData();
              formData.append('file', this.Image);
              formData.append('contactId', data.contactId);
              const httpClientWithoutInterceptor = new HttpClient(
                new HttpXhrBackend({ build: () => new XMLHttpRequest() })
              );
              httpClientWithoutInterceptor
                .post(
                  environment.baseUrl + 'Contact/upload/' + data,
                  formData,
                  {
                    reportProgress: true,
                    observe: 'events',
                  }
                )
                .subscribe();
            }
            this.ref.close(this.addContactForm.value);
          });
      }
    }
  }

  fullNames: any[] = [];
  fullName: any;
  getAllContactSearch() {
    this.httpClient
      .post('Contact/GetAllContactSearch', {})
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((response: any) =>
          response.map((contact: { firstName: string; lastName: string }) => ({
            fullName: `${contact.firstName} ${contact.lastName}`,
          }))
        )
      )
      .subscribe((fullName: any) => {
        this.fullNames = fullName;
      });
  }
  filterContacts(event: any) {
    let query = event.query;
    this.fullName = this.fullNames
      .filter((contact) =>
        contact.fullName.toLowerCase().includes(query.toLowerCase())
      )
      .map((contact) => contact.fullName);
  }

  Identity: any;
  ActiveUserId: any;
  getUserId() {
    this.storage.get('Identity').subscribe((user) => {
      this.Identity = user;
      this.ActiveUserId = this.Identity.userId;
      if (this.addContactForm.get('createdByUserId')?.value == null) {
        this.addContactForm
          .get('createdByUserId')
          ?.patchValue(this.ActiveUserId);
      }
    });
  }

  getCountryExtById(countryId: any) {
    this.httpClient
      .get<any>('Settings/GetCountryById/' + countryId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        const landLineExt = data.landLineExt;
        if (landLineExt == null || landLineExt == 'null') {
          return;
        }
        this.addContactForm.get('ext')?.patchValue(parseInt(landLineExt));
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
