import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  baseUrl = environment.baseUrl;
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": this.baseUrl,
    }),
  };
  constructor(private http: HttpClient) { }

  SubmitContact(obj): Observable<any> {
    return this.http
      .post('Contact/SubmitContact', obj, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": this.baseUrl,
        }),
      })
      .pipe(map(data => data));
  }

  GetAllContacts(): Observable<any> {
    return this.http.get<any>('Contact/GetAllContacts',
      this.httpOptions,
    );
  }
  SmartSearchAllContacts(text: any): Observable<any> {
    return this.http.get<any>('Contact/SmartSearchAllContacts/' + text,
      this.httpOptions,
    );
  }
  SmartSearchByContactId(id: any): Observable<any> {
    return this.http.get<any>('Contact/SmartSearchByContactId/' + id,
      this.httpOptions,
    );
  }

  DeleteContact(ContactId: any): Observable<any> {
    return this.http.delete<any>('Contact/DeleteContact/' + ContactId,
      this.httpOptions,
    );
  }

  GetContactById(ContactId: any): Observable<any> {
    return this.http.get<any>('Contact/GetContactById/' + ContactId,
      this.httpOptions,
    );
  }

  ContactSearch(obj): Observable<any> {
    return this.http
      .post('Contact/ContactSearch', obj, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": this.baseUrl,
        }),
      })
      .pipe(map(data => data));
  }
  GetAllContactSearch(obj): Observable<any> {
    return this.http
      .post('Contact/GetAllContactSearch', obj, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": this.baseUrl,
        }),
      })
      .pipe(map(data => data));
  }

  RemoveImagePath(contactId: any): Observable<any> {
    return this.http.delete<any>('Contact/RemoveImagePath/' + contactId,
      this.httpOptions,
    );
  }

}
