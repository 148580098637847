import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthResponse } from './models/auth-response.model';
import { SignInDetails } from './models/auth-details.model';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class AuthRepository {

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  signIn(signInDetails: SignInDetails): Observable<AuthResponse> {
    return this.httpClient.get<AuthResponse>('User/Login/' +
    signInDetails.username + '/' + signInDetails.password);
  }
}