import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Actions,
  createEffect,
  CreateEffectMetadata,
  ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StorageMap } from '@ngx-pwa/local-storage';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AccessTokenService } from '../shared/access-token.service';
import { AuthRepository } from '../shared/auth.repository';
import {
  SignInProps,
  SignInSuccessProps,
} from '../shared/models/auth-props.model';
import { AuthResponse } from '../shared/models/auth-response.model';
import { AuthActionsTypes, signInFailure, signInSuccess } from './auth.actions';

@Injectable()
export class AuthEffects {
  public signIn$: CreateEffectMetadata = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionsTypes.SignIn),
      switchMap((action: SignInProps) =>
        this.authRepository.signIn(action.signInDetails).pipe(
          map((response: AuthResponse) => {
            if (response.userId === -1) {
              return signInFailure({
                error: 'Wrong credentials. Please try again.',
              });
            } else {
              return signInSuccess({ response });
            }
          }),
          catchError((error) => {
            let errorMessage = 'An unexpected error occurred during sign-in.';
            if (error.status === 401) {
              errorMessage =
                'Unauthorized access. Please check your credentials and try again.';
            }
            return of(signInFailure({ error: errorMessage }));
          })
        )
      )
    )
  );

  public signInSuccess$: CreateEffectMetadata = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionsTypes.SignInSuccess),
        tap((action: SignInSuccessProps) => {
          this.accessTokenService.decodeAccessToken(
            action.response.apolloToken
          );
          this.accessTokenService.setAccessToken(action.response.apolloToken);
        }),
        switchMap((action: SignInSuccessProps) =>
          this.storage.set('Identity', action.response).pipe(map(() => action))
        ),
        tap((action: SignInSuccessProps) => {
          const roleId = action.response.roleId;
          if (roleId === 1 || roleId === 10) {
            this.router.navigate([
              '/pages/new-dashboard/developer-manager-dashboard',
            ]);
          } else if (roleId === 2) {
            this.router.navigate(['/pages/new-dashboard/developer-dashboard']);
          } else {
            this.router.navigate(['/pages/new-dashboard/dashboard365']);
          }
        })
      ),
    { dispatch: false }
  );

  public signOut$: CreateEffectMetadata = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionsTypes.SignOut),
        tap(() => this.accessTokenService.deleteAccessToken()),
        tap(() => this.router.navigate(['/login']))
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly authRepository: AuthRepository,
    private readonly accessTokenService: AccessTokenService,
    private readonly router: Router,
    private storage: StorageMap,
    private store: Store
  ) {}
}
