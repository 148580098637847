import { createAction, props } from '@ngrx/store';
import {
  SignInProps,
  SignInSuccessProps,
} from '../shared/models/auth-props.model';
import { ActionCreatorPropsType } from '../shared/types/action.types';

export enum AuthActionsTypes {
  SignIn = '[Auth] SIGN_IN',
  SignInSuccess = '[Auth] SIGN_IN_SUCCESS',
  SignInFailure = '[Auth] SIGN_IN_FAILURE',
  SignOut = '[Auth] SIGN_OUT',
}

export const signIn: ActionCreatorPropsType<
  AuthActionsTypes.SignIn,
  SignInProps
> = createAction(AuthActionsTypes.SignIn, props<SignInProps>());

export const signInSuccess: ActionCreatorPropsType<
  AuthActionsTypes.SignInSuccess,
  SignInSuccessProps
> = createAction(AuthActionsTypes.SignInSuccess, props<SignInSuccessProps>());

export const signOut = createAction(AuthActionsTypes.SignOut);
export const signInFailure: ActionCreatorPropsType<
  AuthActionsTypes.SignInFailure,
  { error: string }
> = createAction(AuthActionsTypes.SignInFailure, props<{ error: string }>());
