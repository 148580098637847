import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root',
})
export class AuthenticateService {
  Identity: any;
  ActiveUserId: any;
  Permissions: any;
  AddPermission: boolean;
  EditPermission: boolean;
  ViewPermission: boolean;
  DeletePermission: boolean;

  constructor(private storage: StorageMap) {}

  getUserId(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.storage.get('Identity').subscribe(
        (user) => {
          this.Identity = user;
          this.ActiveUserId = this.Identity.userId;
          resolve(this.ActiveUserId);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getItemPermissions() {
    this.storage.get('Identity').subscribe((user) => {
      this.Identity = user;
      this.ActiveUserId = this.Identity.userId;
      this.Permissions = this.Identity.permissions;
      this.Permissions.items.forEach((element) => {
        if (element === 2) {
          this.AddPermission = true;
        } else if (element === 3) {
          this.EditPermission = true;
        } else if (element === 1) {
          this.ViewPermission = true;
        } else if (element === 4) {
          this.DeletePermission = true;
        }
      });
    });
  }
}
