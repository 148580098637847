<section>
  <form
    [formGroup]="addCompanyForm"
    class="flex flex-row justify-content-between mt-4 gap-4"
    [class.disabled-form]="viewCompany"
  >
    <div class="flex flex-row justify-content-center gap-2 w-4">
      <div class="flex flex-column gap-2 mt-2">
        <p>Company Type *</p>
        <p>Company SubType *</p>
        <p>Company Name *</p>
        <p>Country of Origin *</p>
        <p>Regional Country</p>
        <p>Phone 1 *</p>
        <p>Phone 2</p>
        <p>Phone 3</p>
        <p>Fax 1</p>
        <p>Fax 2</p>
      </div>
      <div class="flex flex-column gap-2 w-7">
        <p-dropdown
          autoWidth="false"
          [options]="companyType"
          formControlName="companyTypeId"
          placeholder=" "
          [required]="true"
          optionLabel="title"
          optionValue="companyTypeId"
        >
        </p-dropdown>

        <p-dropdown
          autoWidth="false"
          autoWidth="false"
          [options]="companySubTypes"
          formControlName="companySubTypeId"
          placeholder=" "
          [required]="true"
          optionLabel="title"
          optionValue="companySubTypeId"
        >
        </p-dropdown>

        <p-autoComplete
          formControlName="companyName"
          [suggestions]="filteredCompanies"
          (completeMethod)="filterCompany($event)"
          (onSelect)="onCompanySelect($event)"
          [style]="{ width: '100%', height: '2.5rem', 'font-size': '1.1em' }"
          [class]="
            addCompanyForm.get('companyName').touched &&
            addCompanyForm.get('companyName').invalid
              ? 'ng-invalid ng-dirty'
              : ''
          "
        >
          <ng-template let-company pTemplate="item">
            <div>{{ company.companyName }}</div>
          </ng-template>
        </p-autoComplete>
        <p-dropdown
          [options]="country"
          [required]="true"
          formControlName="countryOfOriginId"
          optionLabel="title"
          [filter]="true"
          filterBy="title"
          [showClear]="true"
          placeholder=" "
          optionValue="countryId"
        >
        </p-dropdown>

        <p-dropdown
          [options]="country"
          formControlName="regionalCountryId"
          (onChange)="onRegionalCountryChange($event)"
          optionLabel="title"
          [filter]="true"
          filterBy="title"
          [showClear]="true"
          placeholder=" "
          optionValue="countryId"
        >
        </p-dropdown>
        <p-inputNumber
          formControlName="phoneOne"
          mode="decimal"
          [useGrouping]="false"
          [class]="
            addCompanyForm.get('phoneOne').touched &&
            addCompanyForm.get('phoneOne').invalid
              ? 'ng-invalid ng-dirty'
              : ''
          "
        ></p-inputNumber>
        <p-inputNumber
          formControlName="phoneTwo"
          mode="decimal"
          [useGrouping]="false"
        ></p-inputNumber>

        <p-inputNumber
          formControlName="phoneThree"
          mode="decimal"
          [useGrouping]="false"
        ></p-inputNumber>

        <p-inputNumber
          formControlName="faxOne"
          mode="decimal"
          [useGrouping]="false"
        ></p-inputNumber>
        <p-inputNumber
          formControlName="faxTwo"
          mode="decimal"
          [useGrouping]="false"
        ></p-inputNumber>
      </div>
    </div>

    <div class="flex flex-row justify-content-center gap-2 w-4">
      <div class="flex flex-column gap-2 mt-2">
        <p>PO Box</p>
        <p>City/State</p>
        <p>Area</p>
        <p>Address</p>
        <p style="margin-top: 6rem">Latitude</p>

        <p>Longitude</p>
        <p>Company Mail</p>
        <p>Website</p>
      </div>
      <div class="flex flex-column gap-2 w-7">
        <input type="text" pInputText formControlName="pobox" />
        <input type="text" pInputText formControlName="city" />

        <input
          *ngIf="!regionalChosen"
          type="text"
          pInputText
          formControlName="area"
        />
        <p-dropdown
          *ngIf="regionalChosen"
          autoWidth="false"
          [options]="ksaAreas"
          formControlName="areaId"
          placeholder=" "
          optionLabel="value"
          optionValue="basicId"
        ></p-dropdown>
        <textarea
          class="w-full"
          pInputTextarea
          formControlName="address"
          [class]="
            addCompanyForm.get('address').touched &&
            addCompanyForm.get('address').invalid
              ? 'ng-invalid ng-dirty'
              : ''
          "
        >
        </textarea>
        <input type="text" pInputText formControlName="latitude" />
        <input type="text" pInputText formControlName="longitude" />
        <input type="text" pInputText formControlName="email" />
        <input type="text" pInputText formControlName="website" />
      </div>
    </div>

    <div class="flex flex-row justify-content-center gap-2 w-4">
      <div class="flex flex-column gap-2 mt-2">
        <p>Classification</p>
        <p>Payment Method</p>
        <p>Payment Period</p>
        <p>Discount</p>
        <p>Currency *</p>
        <p>Second Currency</p>
        <p>Third Currency</p>
        <p>Remarks</p>
      </div>
      <div class="flex flex-column gap-2 w-7">
        <p-dropdown
          autoWidth="false"
          [options]="classification"
          formControlName="classificationId"
          placeholder=" "
          optionLabel="value"
          optionValue="basicId"
        >
        </p-dropdown>
        <p-dropdown
          autoWidth="false"
          [options]="paymentMethod"
          formControlName="paymentModeId"
          placeholder=" "
          optionLabel="value"
          optionValue="basicId"
        >
        </p-dropdown>
        <p-dropdown
          autoWidth="false"
          [options]="paymentPeriod"
          formControlName="paymentPeriodId"
          placeholder=" "
          optionLabel="value"
          optionValue="basicId"
        >
        </p-dropdown>
        <p-inputNumber
          formControlName="discount"
          mode="decimal"
          [minFractionDigits]="2"
          [maxFractionDigits]="5"
          suffix="%"
        ></p-inputNumber>
        <p-dropdown
          autoWidth="false"
          [options]="currency"
          formControlName="defaultCurrencyId"
          optionLabel="symbol"
          [required]="true"
          [showClear]="true"
          placeholder=" "
          optionValue="currencyId"
        >
        </p-dropdown>
        <p-dropdown
          autoWidth="false"
          [options]="currency"
          formControlName="secondCurrencyId"
          optionLabel="symbol"
          [showClear]="true"
          placeholder=" "
          optionValue="currencyId"
        >
        </p-dropdown>
        <p-dropdown
          autoWidth="false"
          [options]="currency"
          formControlName="thirdCurrencyId"
          optionLabel="symbol"
          [showClear]="true"
          placeholder=" "
          optionValue="currencyId"
        >
        </p-dropdown>
        <textarea
          class="w-full"
          pInputTextarea
          formControlName="remarks"
        ></textarea>
      </div>
    </div>

    <div class="flex flex-column w-1" *ngIf="!viewCompany">
      <button
        pButton
        pRipple
        type="button"
        label="Upload Documents"
        (click)="showAddDocuments()"
        class="p-button-outlined"
      ></button>

      <button
        pButton
        pRipple
        type="button"
        label="Add Contact"
        (click)="showAddContact()"
        class="p-button-outlined mt-3"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        label="Submit"
        [disabled]="isSubmitting"
        (click)="onSubmit(addCompanyForm)"
        class="p-button-outlined mt-3"
      ></button>
    </div>
  </form>

  <h2 class="mt-5 title-font">Contacts</h2>
  <p-table [value]="addCompanyForm.get('newContacts').value">
    <ng-template pTemplate="header">
      <tr class="headers-font">
        <th>Full Name</th>
        <th>Position</th>
        <th>Phone</th>
        <th>Mobile</th>
        <th>Professional Email</th>
        <th>Salesman</th>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-contact let-rowIndex="rowIndex">
      <tr class="body-font">
        <td>
          {{ contact.firstName }} {{ contact.middleName }}
          {{ contact.lastName }}
        </td>
        <td *ngIf="contact.positionTitle">{{ contact.positionTitle }}</td>
        <td *ngIf="!contact.positionTitle">
          {{ getPositionName(contact.positionId) }}
        </td>
        <td>
          <div class="flex flex-row">
            <a
              *ngIf="contact.phone"
              pButton
              pRipple
              type="button"
              icon="pi pi-phone"
              class="p-button-rounded p-button-text"
              href="tel:{{ contact.phone }}"
            ></a>
            <a class="flex align-self-center">{{ contact.phone }}</a>
          </div>
        </td>
        <td>
          <div class="flex flex-row">
            <a
              *ngIf="contact.mobile"
              pButton
              pRipple
              type="button"
              icon="pi pi-phone"
              class="p-button-rounded p-button-text"
              href="tel:{{ contact.mobile }}"
            ></a>
            <a
              *ngIf="contact.mobile"
              pButton
              pRipple
              type="button"
              icon="pi pi-comments"
              class="p-button-rounded p-button-text"
              href="https://wa.me/{{ contact.mobile }}"
              target="_blank"
            ></a>
            <a
              class="flex align-self-center"
              href="https://wa.me/{{ contact.mobile }}"
              target="_blank"
              >{{ contact.mobile }}</a
            >
          </div>
        </td>
        <td>
          <div class="flex flex-row align-items-center">
            <a
              *ngIf="contact.professionalEmail"
              href="mailto:{{ contact.professionalEmail }}"
              pButton
              type="button"
              icon="pi pi-envelope"
              class="p-button-rounded p-button-text align-self-center"
            >
            </a>
            <a
              class="align-self-center white-space-nowrap overflow-hidden text-overflow-ellipsis"
            >
              {{ contact.professionalEmail }}
            </a>
          </div>
        </td>

        <td *ngIf="contact.salesman">contact.salesman</td>
        <td *ngIf="!contact.salesman">
          {{ this.getSalesmanName(contact.salesmanId) }}
        </td>
        <td>
          <button
            *ngIf="!viewCompany && contact.contactId !== 0"
            pButton
            pRipple
            icon="pi pi-pencil"
            (click)="showEditContact(contact)"
            class="p-button-rounded p-button-text"
          ></button>
          <button
            *ngIf="viewCompany"
            pButton
            pRipple
            icon="pi pi-eye"
            (click)="showViewContact(contact)"
            class="p-button-rounded p-button-text"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-dialog
    header="Upload Documents"
    [(visible)]="companyDocumentsBool"
    [style]="{ width: '60vw' }"
  >
    <div class="flex flex-row justify-content-between pt-1">
      <span *ngFor="let doc of companyDocsTypes" class="flex flex-column">
        <div class="flex flex-row">
          <p-fileUpload
            [multiple]="true"
            [auto]="true"
            maxFileSize="10000000"
            (onSelect)="onSelectedFile($event, doc)"
            mode="basic"
            chooseLabel="{{ doc.FileName }}"
          >
          </p-fileUpload>
          <span class="align-self-start flex ml-1">{{ doc.documentType }}</span>
        </div>
        <div
          *ngIf="doc.FileNames && doc.FileNames.length > 0"
          class="flex flex-column"
        >
          <div
            *ngFor="let fileName of doc.FileNames"
            class="flex flex-row align-items-center"
          >
            <p class="mb-1 files-font">
              {{ fileName }}
            </p>
            <button
              pButton
              pRipple
              icon="pi pi-times"
              (click)="removeFile(doc, fileName)"
              class="p-button-rounded p-button-text"
            ></button>
          </div>
        </div>
      </span>
    </div>
    <div class="flex justify-content-center mt-2">
      <button
        pButton
        pRipple
        type="button"
        label="Done"
        (click)="showAddDocuments()"
        class="p-button-outlined"
      ></button>
    </div>
  </p-dialog>
</section>
