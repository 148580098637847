import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { Observable, debounceTime } from 'rxjs';
import { environment } from '../../../../environments/environment.prod';
import { NewCompanyComponent } from '../../../companies/dialogues/new-company/new-company.component';
import { AuthenticateService } from '../../../shared/authenticate.service';
import { Item } from '../../shared/models/item.model';
import { AddItemValueComponent } from './add-item-value/add-item-value.component';

@Component({
  selector: 'ngx-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss'],
  providers: [MessageService],
})
export class AddItemComponent implements OnInit {
  addItemForm!: UntypedFormGroup;
  item: Item;
  viewItem: boolean = false;
  editItem: boolean = false;
  isPreferred: boolean = false;
  isProjectLevel: boolean = false;

  suppliers: any[] = [];
  suppliersCurrency: any[] = [];
  HSCodes: any[] = [];
  isPowerSupply: boolean = false;
  itemDocumentsBool: boolean = false;
  itemDocuments: any[] = [];

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];
  basicItemDropDownCategories = [
    { name: 'Main Category', id: 'mainCategoryId', options: [], apiNumber: 15 },
    { name: 'Sub Category', id: 'subCategoryId', options: [], apiNumber: 16 },
    { name: 'Installation', id: 'installationId', options: [], apiNumber: 17 },
    { name: 'Driver', id: 'driverId', options: [], apiNumber: 14 },
  ];
  basicItemInputCategories = [
    { field: 'itemCode', header: '* Item Code' },
    { field: 'family', header: '* Family' },
    { field: 'supplyPower', header: 'Supply Power' },
    { field: 'supplyCurrent', header: 'Supply Current' },
  ];
  bodyItemDropDownCategories = [
    { name: 'Housing', id: 'housingId', options: [], apiNumber: 1 },
    { name: 'Reflector', id: 'reflectorId', options: [], apiNumber: 2 },
    { name: 'Diffuser', id: 'diffuserId', options: [], apiNumber: 3 },
    { name: 'Frame', id: 'frameId', options: [], apiNumber: 4 },
    { name: 'Bracket', id: 'bracketsId', options: [], apiNumber: 5 },
    { name: 'IP Code', id: 'ipCodeId', options: [], apiNumber: 6 },
    { name: 'Beam Type', id: 'beamTypeId', options: [], apiNumber: 7 },
    { name: 'Fitting Color', id: 'fittingColorId', options: [], apiNumber: 8 },
  ];
  bodyItemInputCategories = [
    { field: 'ikCode', header: 'IK Code' },
    { field: 'cri', header: 'CRI' },
    { field: 'ugr', header: 'UGR' },
    { field: 'beamAngle', header: 'Beam Angle' },
  ];
  lampItemDropDownCategories = [
    { name: 'Lamp', id: 'lampId', options: [], apiNumber: 10 },
    { name: 'Lamp Source', id: 'lampSourceId', options: [], apiNumber: 11 },
    { name: 'Base', id: 'baseId', options: [], apiNumber: 12 },
    { name: 'Color Temp', id: 'colorTempId', options: [], apiNumber: 13 },
  ];
  lampItemInputCategories = [
    { field: 'voltage', header: 'Voltage' },
    { field: 'netLumen', header: 'Net Lumen' },
    { field: 'ledlumen', header: 'LED Lumen' },
    { field: 'efficiency', header: 'Efficiency' },
  ];
  shapeItemDropDownCategories = [
    { name: 'Shape', id: 'shapeId', options: [], apiNumber: 9 },
  ];
  shapeItemInputCategories = [
    { field: 'length', header: 'Length' },
    { field: 'width', header: 'Width' },
    { field: 'height', header: 'Height' },
    { field: 'diameter', header: 'Diameter' },
    { field: 'weight', header: 'Weight' },
    { field: 'cutoutlength', header: 'Cutout Length' },
    { field: 'cutoutwidth', header: 'Cutout Width' },
    { field: 'cutoutdiameter', header: 'Cutout Diameter' },
  ];

  mainPriceItemInputCategories = [
    { field: 'grossPrice', header: 'Gross Price' },
  ];
  extraPriceItemInputCategories = [
    { field: 'specialPrice', header: 'Special Price' },
    { field: 'specialPriceTwo', header: 'Special Price Two' },
    { field: 'specialPriceThree', header: 'Special Price Three' },
  ];

  voltageItemDropDownCategories = [
    {
      name: 'Constant Current/ Voltage',
      id: 'contantCurrentVoltageId',
      options: [],
      apiNumber: 20,
    },
    { name: 'Driver Type', id: 'driverTypeId', options: [], apiNumber: 21 },
    {
      name: 'Output Power',
      id: 'nominalOutputPowerId',
      options: [],
      apiNumber: 22,
    },
    {
      name: 'Rated Current/ Voltage',
      id: 'ratedCurrentVoltageId',
      options: [],
      apiNumber: 24,
    },
    { name: 'IP Code', id: 'ipCodeId', options: [], apiNumber: 6 },
  ];
  voltageItemInputCategories = [
    { field: 'modelNo', header: 'Model Number' },
    { field: 'ratedSupplyVoltage', header: 'Rated Supply Voltage' },
    { field: 'inputVoltageDc', header: 'Input Voltage DC' },
    { field: 'inputVoltageAc', header: 'Input Voltage AC' },
  ];
  extravoltageItemInputCategories = [
    { field: 'mainsFrequency', header: 'Main Frequency' },
    { field: 'powerFactor', header: 'Power Factor' },
    { field: 'inrushCurrent', header: 'Inrush Current' },
    { field: 'dimmingRange', header: 'Dimming Range' },
    { field: 'lifeTime', header: 'Life Time' },
    { field: 'totalHarmonicDistortion', header: 'Total Harmonic Distortion' },
    { field: 'ambientTemperaturerange', header: 'Ambient Temperature Range' },
    { field: 'weight', header: 'Weight' },
  ];
  itemCheckBoxCategories = [
    { field: 'preferred', header: 'Is Preferred' },
    { field: 'isService', header: 'Is Service' },
    { field: 'outDoor', header: '* Is Outdoor' },
    { field: 'inDoor', header: '* Is Indoor' },
    { field: 'isLocked', header: 'Is Locked' },
    { field: 'special', header: 'Is Special' },
  ];
  preferredChckBoxCategories = [
    { field: 'preferredByBrand', header: 'Preferred By Brand' },
    { field: 'preferredByPrice', header: 'Preferred By Price' },
  ];

  images: any[] = [];
  imageAsStrings: any[] = [];
  activeIndex: number = 0;
  imageDisplay: boolean;
  imageBaseUrl = environment.imagesBaseUrl;
  CurrentItemId: any;
  DuplicatedFromItemId: any;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly httpClient: HttpClient,
    public readonly dialogService: DialogService,
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    public readonly ref: DynamicDialogRef,
    private readonly authService: AuthenticateService,
    private messageService: MessageService
  ) {}

  userId: any;
  async getUserId() {
    this.userId = await this.authService.getUserId();
  }

  ngOnInit() {
    this.item = this.dynamicDialogConfig.data.items || {};
    this.viewItem = this.dynamicDialogConfig.data.isView || false;
    this.editItem = this.dynamicDialogConfig.data.isEdit || false;
    this.isPreferred = this.dynamicDialogConfig.data.isPreferred || false;
    this.isProjectLevel = this.dynamicDialogConfig.data.isProjectLevel || false;
    this.addItemForm = this.formBuilder.group({
      baseId: [this.item.baseId || null],
      beamAngle: [this.item.beamAngle || null],
      beamTypeId: [this.item.beamTypeId || null],
      bracketsId: [this.item.bracketsId || null],
      colorTempId: [this.item.colorTempId || null],
      cutoutdiameter: [this.item.cutoutdiameter || null],
      cutoutlength: [this.item.cutoutlength || null],
      cutoutwidth: [this.item.cutoutwidth || null],
      description: [this.item.description || null],
      diameter: [this.item.diameter || null],
      diffuserId: [this.item.diffuserId || null],
      discount: [this.item.discount || null],
      discountedPrice: [
        this.item.discountedPrice ||
          (
            Number(this.item.grossprice) -
            Number(this.item.grossprice) * (Number(this.item.discount) / 100)
          ).toFixed(2) ||
          null,
      ],
      driverId: [this.item.driverId || null],
      family: [this.item.family || null, Validators.required],
      fittingColorId: [this.item.fittingColorId || null],
      frameId: [this.item.frameId || null],
      grossPrice: [this.item.grossprice || null],
      height: [this.item.height || null],
      housingId: [this.item.housingId || null],
      ikCode: [this.item.ikCode || null],
      installationId: [this.item.installationId || null],
      ipCodeId: [this.item.ipcodeId || null],
      isLocked: [this.item.isLocked || null],
      itemCode: [this.item.itemCode || null, Validators.required],
      itemId: [this.item.itemId || 0],
      lampId: [this.item.lampId || null],
      lampSourceId: [this.item.lampSourceId || null],
      lampsCount: [this.item.lampsCount || null],
      ledlumen: [this.item.ledlumen || null],
      length: [this.item.length || null],
      mainCategoryId: [this.item.mainCategoryId || null],
      mainDescription: [null],
      netLumen: [this.item.netLumen || null],
      notes: [this.item.notes || null],
      preferred: [this.item.preferred || this.isPreferred || null],
      reflectorId: [this.item.reflectorId || null],
      shapeId: [this.item.shapeId || null],
      special: [this.item.special || null],
      specialPrice: [this.item.specialPrice || null],
      specialPriceThree: [this.item.specialPriceThree || null],
      specialPriceTwo: [this.item.specialPriceTwo || null],
      subCategoryId: [this.item.subCategoryId || null],
      supplierId: [this.item.supplierId || null, Validators.required],
      supplyCurrent: [this.item.supplyCurrent || null],
      supplyPower: [this.item.supplyPower || null],
      totalWatt: [this.item.totalWatt || null],
      updatedByUserId: [null],
      voltage: [this.item.voltage || null],
      wattage: [this.item.wattage || null],
      weight: [this.item.weight || null],
      width: [this.item.width || null],
      ugr: [this.item.ugr || null],
      cri: [this.item.cri || null],
      modelNo: [this.item.modelNo || null],
      ratedSupplyVoltage: [this.item.ratedSupplyVoltage || null],
      inputVoltageDc: [this.item.inputVoltageDc || null],
      inputVoltageAc: [this.item.inputVoltageAc || null],
      mainsFrequency: [this.item.mainsFrequency || null],
      powerFactor: [this.item.powerFactor || null],
      inrushCurrent: [this.item.inrushCurrent || null],
      dimmingRange: [this.item.dimmingRange || null],
      lifeTime: [this.item.lifeTime || null],
      totalHarmonicDistortion: [this.item.totalHarmonicDistortion || null],
      ambientTemperaturerange: [this.item.ambientTemperaturerange || null],
      driverTypeId: [this.item.driverTypeId || null],
      nominalOutputPowerId: [this.item.nominalOutputPowerId || null],
      ratedCurrentVoltageId: [this.item.ratedCurrentVoltageId || null],
      contantCurrentVoltageId: [this.item.contantCurrentVoltageId || null],
      supplierCurrencyId: [
        this.item.supplierCurrencyId || null,
        Validators.required,
      ],
      inDoor: [this.item.indoor || false],
      outDoor: [this.item.outdoor || false],
      hscodeid: [this.item.hscodeid || ''],
      isService: [this.item.isService || false],
      updatedDate: [this.item.updatedDate || null],
      createdDate: [this.item.createdDate || null],
      preferredByBrand: [this.item.preferredByBrand || false],
      preferredByPrice: [this.item.preferredByPrice || false],
      duplicatedFromItemId: [this.item.duplicatedFromItemId || 0],
      efficiency: [this.item.efficiency || null],
      projectItemHistoryId: [this.item.projectItemHistoryId || 0],
    });
    this.images = this.item.itemImages || [];
    if (this.item.defaultImage != null && this.item.itemImages.length == 0) {
      this.images.push(this.item.defaultImage);
    }
    this.CurrentItemId = this.addItemForm.controls.itemId.value;
    this.DuplicatedFromItemId =
      this.addItemForm.controls.duplicatedFromItemId.value;
    this.getSupplier();
    this.getHSCode();
    this.getDocumentsTypes();
    this.GetAllItemImages();

    for (let i in this.basicItemDropDownCategories) {
      if (this.basicItemDropDownCategories[i].apiNumber == 16) {
        this.httpClient
          .get<any>(
            'Item/GetAllSpecsByCategoryId/' +
              this.basicItemDropDownCategories[i].apiNumber
          )
          .subscribe((data) => {
            this.basicItemDropDownCategories[i].options = data;
          });
      } else {
        this.httpClient
          .get<any>(
            'Item/GetAllSpecsByCategoryId/' +
              this.basicItemDropDownCategories[i].apiNumber
          )
          .subscribe((data) => {
            this.basicItemDropDownCategories[i].options = data;
          });
      }
    }
    for (let i in this.bodyItemDropDownCategories) {
      this.httpClient
        .get<any>(
          'Item/GetAllSpecsByCategoryId/' +
            this.bodyItemDropDownCategories[i].apiNumber
        )
        .subscribe((data) => {
          this.bodyItemDropDownCategories[i].options = data;
        });
    }
    for (let i in this.lampItemDropDownCategories) {
      this.httpClient
        .get<any>(
          'Item/GetAllSpecsByCategoryId/' +
            this.lampItemDropDownCategories[i].apiNumber
        )
        .subscribe((data) => {
          this.lampItemDropDownCategories[i].options = data;
        });
    }
    for (let i in this.shapeItemDropDownCategories) {
      this.httpClient
        .get<any>(
          'Item/GetAllSpecsByCategoryId/' +
            this.shapeItemDropDownCategories[i].apiNumber
        )
        .subscribe((data) => {
          this.shapeItemDropDownCategories[i].options = data;
        });
    }
    for (let i in this.voltageItemDropDownCategories) {
      this.httpClient
        .get<any>(
          'Item/GetAllSpecsByCategoryId/' +
            this.voltageItemDropDownCategories[i].apiNumber
        )
        .subscribe((data) => {
          this.voltageItemDropDownCategories[i].options = data;
        });
    }
    if (this.addItemForm.get('supplierId')?.value != null) {
      this.getCompanyCurrency(this.addItemForm.get('supplierId')?.value);
    }

    this.addItemForm.get('supplierId')?.valueChanges.subscribe((value) => {
      this.getCompanyCurrency(value);

      let company = this.suppliers.find(
        (company) => company.companyId == value
      );
      if (company) {
        this.addItemForm.get('discount')?.patchValue(company.discount);
      }
    });

    this.addItemForm.get('mainCategoryId')?.valueChanges.subscribe((value) => {
      if (value == 328) {
        this.isPowerSupply = true;
      } else {
        this.isPowerSupply = false;
      }
    });
    this.addItemForm.get('discount')?.valueChanges.subscribe(() => {
      this.discountedPrice();
    });
    this.addItemForm.get('grossPrice')?.valueChanges.subscribe(() => {
      this.discountedPrice();
    });
    this.addItemForm.get('lampsCount').valueChanges.subscribe(() => {
      this.totalWattage();
    });
    this.addItemForm.get('wattage').valueChanges.subscribe(() => {
      this.totalWattage();
    });
    const controlsToWatch = [
      'driverId',
      'installationId',
      'subCategoryId',
      'wattage',
      'netLumen',
      'colorTempId',
      'ipCodeId',
      'lampsCount',
    ];
    controlsToWatch.forEach((controlName) => {
      this.addItemForm
        .get(controlName)
        .valueChanges.pipe(debounceTime(300))
        .subscribe(() => {
          this.generateDescription();
        });
    });
    this.getUserId();

    if (this.viewItem == false && this.addItemForm.get('itemId').value != 0) {
      this.httpClient
        .get<any>(
          'Item/getAllDownloadAbleItemDocs/' +
            this.addItemForm.get('itemId').value
        )
        .subscribe((itemDocs) => {
          for (const inputItem of itemDocs) {
            const targetType = this.itemDocumentTypes.find(
              (targetItem) =>
                targetItem.itemDocumentTypeId === inputItem.itemDocumentTypeId
            );

            if (targetType) {
              targetType.exists = inputItem.exists;
              targetType.FileNames = inputItem.itemDocuments.map(
                (doc) => doc.fileName
              );
            }
          }
        });
    }
  }

  getSupplier() {
    this.httpClient
      .get<any>('company/GetAllCompaniesDropDownByCompanyTypeId/' + 2)
      .subscribe((data) => {
        this.suppliers = data;
        if (this.item.supplierId != null) {
          let company = this.suppliers.find(
            (company) => company.companyId == this.item.supplierId
          );
          if (company && company.discount != null) {
            this.addItemForm.get('discount')?.patchValue(company.discount);
          }
        }
      });
  }
  getCompanyCurrency(supplierId) {
    this.httpClient
      .get<any>('Company/GetAllCompanyCurrenciesByCompanyId/' + supplierId)
      .subscribe((data) => {
        this.suppliersCurrency = data;
      });
  }
  getHSCode() {
    this.httpClient.get<any>('Item/getAllHsCodes').subscribe((data) => {
      this.HSCodes = data;
    });
  }

  imageClick(index: number) {
    this.activeIndex = index;
    this.imageDisplay = true;
  }

  itemDocumentTypes: any[] = [];
  getDocumentsTypes() {
    this.httpClient
      .get<any>('Item/getAllItemDocumentTypes')
      .subscribe((data) => {
        this.itemDocumentTypes = data;
      });
  }

  addItemValue(item: any, dropDownCategories: any[]) {
    this.dialogService
      .open(AddItemValueComponent, {
        data: item,
        header: 'Add Item Category',
      })
      .onClose.subscribe((res) => {
        if (res) {
          this.updateOptions(item, res, dropDownCategories);
        }
      });
  }

  updateOptions(item: any, res: any, dropDownCategories: any[]) {
    const newItem = {
      value: res,
    };
    dropDownCategories.filter((x) => x.id == item.id)[0].options.push(newItem);
  }

  discountedPrice() {
    const grossPrice = this.addItemForm.get('grossPrice').value || 0;
    const discount = this.addItemForm.get('discount').value || 0;
    this.addItemForm
      .get('discountedPrice')
      .patchValue((grossPrice - grossPrice * (discount / 100)).toFixed(2));
  }
  totalWattage() {
    const lampsCount = this.addItemForm.get('lampsCount').value || 1;
    const wattage = this.addItemForm.get('wattage').value || 1;
    this.addItemForm.get('totalWatt').patchValue(lampsCount * wattage);
  }

  async submit() {
    if (!this.isFormValid()) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill all required fields',
      });
      return;
    }

    const formattedDate = this.getFormattedDate();
    this.setFormDates(formattedDate);
    this.item = this.addItemForm.value;

    try {
      let data;

      if (this.isProjectLevel == true) {
        const obj = {
          ...this.item,
          isAccessoryHistory: false,
          ProjectId: 0,
          ProjectItemDetailsId: 0,
          ItemId: 0,
        };
        await this.httpClient
          .post('Item/EditProjectItemHistory', obj)
          .toPromise();

        this.ref.close(this.item);
      } else {
        data = await this.submitItem(this.item);
        this.ref.close(data);
      }

      await this.uploadImages(data);
      await this.uploadItemDocs(data);
    } catch (error) {
      console.error(error);
    }
  }

  isFormValid() {
    return (
      this.addItemForm.valid &&
      (this.addItemForm.get('inDoor').value ||
        this.addItemForm.get('outDoor').value)
    );
  }

  getFormattedDate() {
    const getDate = new Date();
    return getDate.toISOString().slice(0, -1);
  }

  setFormDates(formattedDate: string) {
    this.addItemForm.get('updatedDate')?.patchValue(formattedDate);
    if (!this.addItemForm.get('createdDate')?.value) {
      this.addItemForm.get('createdDate')?.patchValue(formattedDate);
    }
    this.addItemForm.get('updatedByUserId')?.patchValue(this.userId);
  }

  async submitItem(item: any) {
    return this.httpClient.post('Item/SubmitItem', item).toPromise();
  }

  async uploadImages(data: any) {
    if (this.CurrentItemId == 0) {
      for (const file of this.images) {
        if (file && file.File) {
          const formData = new FormData();
          formData.append('file', file.File, file.File.name);
          const httpClientWithoutInterceptor = new HttpClient(
            new HttpXhrBackend({ build: () => new XMLHttpRequest() })
          );
          await httpClientWithoutInterceptor
            .post(environment.baseUrl + 'Item/UploadImage/' + data, formData, {
              reportProgress: true,
              observe: 'events',
            })
            .toPromise();
        }
      }
    }
  }

  async uploadItemDocs(data: any) {
    for (const doc of this.itemDocumentTypes) {
      if (doc.Files) {
        for (const File of doc.Files) {
          const formData = new FormData();
          formData.append('file', File, File.name);
          await this.httpClient
            .post(
              'Item/UploadItemDoc/' +
                data +
                '/' +
                doc.itemDocumentTypeId +
                '/' +
                this.userId,
              formData,
              { reportProgress: true, observe: 'events' }
            )
            .toPromise();
        }
      }
    }
  }

  AddImage(event) {
    if (!this.images) {
      this.images = [];
    }

    const handleFileUpload = (file) => {
      var imgObj = {
        url: null,
        File: file,
      };
      var reader = new FileReader();
      reader.onload = (event: any) => {
        imgObj.url = event.target.result;
      };
      reader.readAsDataURL(file);

      if (this.CurrentItemId == 0) {
        this.images.push(imgObj);
      } else {
        const formData = new FormData();
        formData.append('file', imgObj.File, imgObj.File.name);
        const httpClientWithoutInterceptor = new HttpClient(
          new HttpXhrBackend({ build: () => new XMLHttpRequest() })
        );
        httpClientWithoutInterceptor
          .post(
            environment.baseUrl + 'Item/UploadImage/' + this.CurrentItemId,
            formData,
            {
              reportProgress: true,
              observe: 'events',
            }
          )
          .subscribe(() => {
            this.GetAllItemImages();
          });
      }
    };

    for (let i = 0; i < event.target.files.length; i++) {
      handleFileUpload(event.target.files[i]);
    }
  }

  GetAllItemImages() {
    if (this.CurrentItemId != 0) {
      this.httpClient
        .get<any>('Item/GetAllItemImages/' + this.CurrentItemId)
        .subscribe((res) => {
          this.images = res;
        });
    } else if (this.DuplicatedFromItemId != 0) {
      this.httpClient
        .get<any>('Item/GetAllItemImages/' + this.DuplicatedFromItemId)
        .subscribe((res) => {
          this.images = res;
        });
    }
  }

  addSupplier() {
    this.dialogService.open(NewCompanyComponent, {
      data: {},
      header: 'Add Supplier',
      width: '80%',
      height: '90%',
    });
  }

  onSelectedFile(event: any, doc: any) {
    if (event.files && event.files.length > 0) {
      if (!doc.Files) {
        doc.Files = [];
        doc.FileNames = [];
      }

      for (const file of event.files) {
        doc.Files.push(file);
        doc.FileNames.push(file.name);
      }
    }
  }

  deleteImage(index: number, event: Event) {
    event.stopPropagation();

    if (this.CurrentItemId === 0) {
      this.images.splice(index, 1);
    } else {
      const imageId = this.images[index].imageId;
      this.httpClient.delete<any>('Item/DeleteImage/' + imageId).subscribe();
      this.images.splice(index, 1);
    }
  }

  showAddDocuments() {
    this.itemDocumentsBool = !this.itemDocumentsBool;
  }

  removeFile(doc, fileName) {
    const index = doc.FileNames.indexOf(fileName);
    if (index > -1) {
      doc.FileNames.splice(index, 1);
      doc.Files.splice(index, 1);
    }
  }
  generateDescription() {
    const formValues = this.addItemForm.value;

    const dropdownObjects = [].concat(
      this.bodyItemDropDownCategories,
      this.voltageItemDropDownCategories,
      this.lampItemDropDownCategories,
      this.basicItemDropDownCategories
    );

    const findLabel = (valueId: any, optionsId: string) => {
      const optionsObj = dropdownObjects.find((obj) => obj.id === optionsId);
      const option = optionsObj
        ? optionsObj.options.find((opt) => opt.specId === valueId)
        : null;
      return option ? option.value : '';
    };

    const isNumericString = (str) => {
      return /^[0-9-/]*$/.test(str);
    };

    const autoDesDriverValue = formValues.driverId
      ? ', ' + findLabel(formValues.driverId, 'driverId')
      : '';

    const autoDesInstallationsValue =
      findLabel(formValues.installationId, 'installationId') || '';
    const autoDesSubCategoryValue = formValues.subCategoryId
      ? ', ' + findLabel(formValues.subCategoryId, 'subCategoryId')
      : '';

    const autoDesWattageValue = formValues.wattage
      ? formValues.wattage + 'W'
      : '';

    const autoDesLumenValue = formValues.netLumen
      ? ', ' + formValues.netLumen + 'Lm'
      : '';

    const colorTempLabel = findLabel(formValues.colorTempId, 'colorTempId');
    const autoDesColorTemValue = formValues.colorTempId
      ? ', ' + colorTempLabel + (isNumericString(colorTempLabel) ? 'K' : '')
      : '';

    const autoDesIPCodesValue = formValues.ipCodeId
      ? ', IP' + findLabel(formValues.ipCodeId, 'ipCodeId')
      : '';

    const autoDeslampsCount = formValues.lampsCount
      ? ', ' + (formValues.lampsCount === 1 ? '' : formValues.lampsCount + 'x')
      : '';

    const autoGeneratedDescription =
      autoDesInstallationsValue +
      autoDesSubCategoryValue +
      autoDeslampsCount +
      autoDesWattageValue +
      autoDesLumenValue +
      autoDesColorTemValue +
      autoDesDriverValue +
      autoDesIPCodesValue;

    this.addItemForm
      .get('description')
      .patchValue(autoGeneratedDescription, { emitEvent: false });
  }

  selectedImageIndex: number | null = null;

  setDefaultImage(imageId: any): Observable<any> {
    return this.httpClient.post<any>(
      'Item/SetDefaultImage/' + imageId + '/' + 0 + '/' + 0,
      {}
    );
  }

  selectImage(index: number) {
    this.selectedImageIndex = index;
  }

  setDefault(index: number) {
    const selectedImage = this.images[index];
    this.setDefaultImage(selectedImage.imageId).subscribe();
  }
}
