import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { StorageMap } from '@ngx-pwa/local-storage';
import { GeneralService } from 'app/shared/services/general.service';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { NewCompanyComponent } from '../../../companies/dialogues/new-company/new-company.component';
import { AddContactComponent } from '../../../contacts/dialogues/add-contact/add-contact.component';
import { AuthenticateService } from '../../../shared/authenticate.service';
import { Project } from '../../shared/models/project.model';

@Component({
  selector: 'ngx-add-inquiry',
  templateUrl: './add-inquiry.component.html',
  styleUrls: ['./add-inquiry.component.scss'],
})
export class AddInquiryComponent implements OnInit, OnDestroy {
  inquiryForm!: FormGroup;
  project: Project = this.dynamicDialogConfig.data.inquiry;
  isType = this.dynamicDialogConfig.data.isType;
  isInquiry = this.dynamicDialogConfig.data.isInquiry;

  private destroy$: Subject<void> = new Subject<void>();

  priorityList = [{ value: 'A' }, { value: 'B' }, { value: 'C' }];

  ksaAreas: any[] = [];

  designers: any[] = [];
  projects: any[] = [];
  currencies: any[] = [];
  salesman: any[] = [];
  timeFrames: any[] = [];
  stages: any[] = [];
  statuses: any[] = [];
  countries: any[] = [];
  sources: any[] = [];
  clients: any[] = [];
  branches: any[] = [];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly http: HttpClient,
    private readonly storage: StorageMap,
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    private readonly dialogService: DialogService,
    private readonly authService: AuthenticateService,
    private readonly ref: DynamicDialogRef,
    private readonly generalService: GeneralService
  ) {}

  ngOnInit(): void {
    this.getUserId();
    this.Authentication();
    this.createForm();
    this.callApis();
  }

  createForm(): void {
    this.inquiryForm = this.formBuilder.group({
      projectId: [this.project.projectId || 0],
      projectName: [this.project.projectName || null, Validators.required],
      shortName: [this.project.shortName || null, Validators.required],
      clientId: [this.project.clientId || null, Validators.required],
      contactId: [this.project.contactId || null, Validators.required],
      mainConsultantId: [this.project.mainConsultantId || null],
      mepConsultantId: [this.project.mepConsultantId || null],
      mainContractorId: [this.project.mainContractorId || null],
      mepContractorId: [this.project.mepContractorId || null],
      architectId: [this.project.architectId || null],
      sourceId: [this.project.sourceId || null],
      lightingDhId: [this.project.lightingDhId || null],
      ownerRepresentativeId: [this.project.ownerRepresentativeId || null],
      projectManagerId: [this.project.projectManagerId || null],
      otherStockholders: [this.project.otherStockholders || null],
      priority: [
        this.project.priority ? this.project.priority.trim() : null || null,
        Validators.required,
      ],
      stageId: [this.project.stageId || null],
      statusId: [this.project.statusId || null, Validators.required],
      timeFrameId: [this.project.timeFrameId || null, Validators.required],
      notes: [this.project.notes || null],
      followUpDate: [
        this.project.followUpDate
          ? new Date(this.formatDateToDdMmYy(this.project.followUpDate))
          : null,
        Validators.required,
      ],
      location: [this.project.location || null, Validators.required],
      dateAwarded: [
        this.project.dateAwarded
          ? new Date(this.formatDateToDdMmYy(this.project.dateAwarded))
          : null,
        Validators.required,
      ],
      contactFirstName: [this.project.contactFirstName || null],
      contactMiddleName: [this.project.contactMiddleName || null],
      mainProjectNumber: [this.project.mainProjectNumber || null],
      contactLastName: [this.project.contactLastName || null],
      companyName: [this.project.companyName || null],
      isHot: [this.project.isHot || null],
      stage: [this.project.stage || null],
      SalesmanId: [this.project.salesmanId || null, Validators.required],
      designerId: [this.project.designerId || null],
      reasonOfRejection: [this.project.reasonOfRejection || null],
      branchId: [this.project.branchId || null, Validators.required],
      hasComment: [this.project.hasComment || null],
      isOld: [this.project.isOld || false],
      deadline: [this.project.deadline || null],
      typesNumber: [this.project.typesNumber, Validators.required],
      totalQuantity: [this.project.totalQuantity, Validators.required],
      totalBudget: [this.project.totalBudget, Validators.required],
      currencyId: [this.project.currencyId, Validators.required],
      isProject: [this.project.isProject || false],
      isQuickOffer: [this.project.isQuickOffer || false],
      gov: [this.project.gov || false],
      isLatest: [this.project.isLatest || false],
      isLost: [this.project.isLost || false],
      userId: [20],
      projectLostNote: [this.project.projectLostNote || null],
      ourDesign: [this.project.ourDesign || false],
      approvalToAddSubmitted: false,
      approvalToIssueOffer: false,
      offerAwarness: false,
      constingIsReady: false,
      areaId: [this.project.areaId || null],
      comment: [this.project.comment || null],
    });
    this.inquiryForm.get('clientId')?.valueChanges.subscribe((value) => {
      this.GetAllContactsByClientId(value);
    });

    this.inquiryForm.get('timeFrameId')?.valueChanges.subscribe((value) => {
      this.subscribeToTimeframeChanges(value);
    });

    this.inquiryForm.get('branchId')?.valueChanges.subscribe((value) => {
      if (value === 1) {
        this.inquiryForm.get('currencyId')?.patchValue(1);
      } else if (value === 2) {
        this.inquiryForm.get('currencyId')?.patchValue(7);
      } else if (value === 3) {
        this.inquiryForm.get('currencyId')?.patchValue(4);
      }
    });

    if (this.inquiryForm.get('clientId')?.value) {
      this.GetAllContactsByClientId(this.inquiryForm.get('clientId')?.value);
    }
  }

  callApis(): void {
    this.getAllBasicsByCategoryId(12).subscribe(
      (data) => (this.ksaAreas = data)
    );

    this.generalService
      .getClients()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => (this.clients = data));

    this.generalService
      .getCountries()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => (this.countries = data));

    this.generalService
      .getStatuses()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => (this.statuses = data));

    this.generalService
      .getStages()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => (this.stages = data));

    this.generalService
      .getTimeFrames()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => (this.timeFrames = data));

    this.generalService
      .getSalesman()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => (this.salesman = data));

    this.generalService
      .getCurrencies()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => (this.currencies = data));

    this.generalService
      .getProjects()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => (this.projects = data));

    this.generalService
      .getBranches()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => (this.branches = data));

    this.generalService
      .getDesigners()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => (this.designers = data));

    this.generalService
      .getSources()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => (this.sources = data));
  }

  getAllBasicsByCategoryId(BasicCategoryid: any): Observable<any> {
    return this.http.get<any>(
      'company/getAllBasicsByCategoryId/' + BasicCategoryid
    );
  }

  userId: any;
  async getUserId() {
    this.userId = await this.authService.getUserId();
  }

  contacts: any[] = [];
  GetAllContactsByClientId(clientId: any) {
    this.http
      .get<any>('company/GetAllContactsByCompanyId/' + clientId)
      .pipe(
        map((response: any) =>
          response.map((contact: { firstName: string; lastName: string }) => ({
            fullName: `${contact.firstName} ${contact.lastName}`,
            ...contact,
          }))
        )
      )
      .subscribe((res) => {
        this.contacts = [...res];
      });
  }

  DoQuickOfferWorkflowPermission: boolean = false;
  Permissions: any;
  ActiveUserId: any;
  Identity: any;
  Authentication() {
    this.storage.get('Identity').subscribe((user) => {
      this.Identity = user;
      this.ActiveUserId = this.Identity.userId;
      this.Permissions = this.Identity.permissions;

      this.Permissions.workFlow.forEach((element) => {
        if (element === 17) {
          this.DoQuickOfferWorkflowPermission = true;
        }
      });
    });
  }

  viewAddCompany() {
    this.dialogService.open(NewCompanyComponent, {
      header: 'Add Client',
      width: '100%',
      height: '90%',
      data: [{}, 'add'],
    });
  }

  viewAddContact() {
    this.dialogService.open(AddContactComponent, {
      data: [],
      header: 'Add Contact',
      width: '80%',
    });
  }
  files: any[] = [];
  returnedId: any;

  AddFile(event) {
    if (!this.files) {
      this.files = [];
    }

    const handleFileUpload = (file) => {
      var fileObj = {
        url: null,
        File: file,
        name: file.name,
        type: file.type.split('/')[0],
      };

      this.files.push(fileObj);
    };

    for (let i = 0; i < event.target.files.length; i++) {
      handleFileUpload(event.target.files[i]);
    }
  }

  deleteFile(index: number) {
    if (index > -1 && index < this.files.length) {
      this.files.splice(index, 1);
    }
  }

  isSubmitting: boolean = false;
  onSubmit() {
    if (this.inquiryForm.valid) {
      this.isSubmitting = true;
      this.http
        .post('Project/SubmitProject', this.inquiryForm.value)
        .subscribe({
          next: (res) => {
            this.returnedId = res;
            this.uploadFiles();
            this.isSubmitting = false;
            this.ref.close(true);
          },
          error: (err) => {
            console.error(err);
            this.isSubmitting = false;
            this.ref.close(false);
          },
        });
    } else {
      alert('Please fill all required fields');
    }
  }

  uploadFiles() {
    this.files.forEach((file) => {
      const formData = new FormData();
      formData.append('file', file.File, file.File.name);

      this.http
        .post('Project/upload/' + this.returnedId, formData, {
          reportProgress: true,
          observe: 'events',
        })
        .subscribe();
    });
  }

  subscribeToTimeframeChanges(selectedTimeframe: any) {
    let monthsToAdd = 0;

    if (selectedTimeframe === 100) {
      monthsToAdd = 3;
    } else if (selectedTimeframe === 101) {
      monthsToAdd = 6;
    } else if (selectedTimeframe === 102) {
      monthsToAdd = 9;
    } else if (selectedTimeframe === 260) {
      monthsToAdd = 12;
    } else if (selectedTimeframe === 261) {
      monthsToAdd = 18;
    } else if (selectedTimeframe === 262) {
      monthsToAdd = 24;
    }

    const date = new Date();
    const day = date.getDate();

    date.setMonth(date.getMonth() + monthsToAdd);

    const lastDayOfNewMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();
    date.setDate(Math.min(day, lastDayOfNewMonth));

    this.inquiryForm.get('followUpDate').patchValue(date);
  }

  formatDateToDdMmYy(dateString) {
    const parts = dateString.split('/');
    const date = new Date(+parts[2], +parts[1] - 1, +parts[0]);

    return date;
  }

  showArea: boolean = false;
  onCountryChange(event) {
    const patchValue = event.value === 137 ? 3 : event.value === 201 ? 1 : 2;
    this.showArea = event.value === 218 ? true : false;

    if (this.showArea) {
      this.inquiryForm.get('areaId').setValidators([Validators.required]);
    } else {
      this.inquiryForm.get('areaId').clearValidators();
    }
    this.inquiryForm.get('areaId').updateValueAndValidity();

    this.inquiryForm.get('branchId').patchValue(patchValue);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
