import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'ngx-view-columns',
  templateUrl: './view-columns.component.html',
  styleUrls: ['./view-columns.component.scss'],
})
export class ViewColumnsComponent implements OnInit {
  columnsForm: UntypedFormGroup;
  contactFields = [
    { name: 'Contact ID', value: 'contactId' },
    { name: 'Name', value: 'firstName' },
    { name: 'Position Title', value: 'positionTitle' },
    { name: 'Company Name', value: 'companyName' },
    { name: 'Company Type', value: 'companyType' },
    { name: 'Country', value: 'country' },
    { name: 'Mobile', value: 'mobile' },
    { name: 'Email', value: 'professionalEmail' },
    { name: 'Skype Name', value: 'skypeName' },
    { name: 'Teams', value: 'teams' },
    { name: 'Area', value: 'area' },
    { name: 'Classification', value: 'classification' },
    { name: 'Company Email', value: 'companyEmail' },
    { name: 'Company Sub Type', value: 'companySubType' },
    { name: 'KSA Area', value: 'ksaArea' },
    { name: 'Remarks', value: 'remarks' },
    { name: 'Salesman Name', value: 'salesmanName' },
  ];
  companyFields = [
    { name: 'Company ID', value: 'companyId' },
    { name: 'Company Name', value: 'companyName' },
    { name: 'Abbreviation', value: 'abbreviation' },
    { name: 'Phone 1', value: 'phoneOne' },
    { name: 'Phone 2', value: 'phoneTwo' },
    { name: 'Phone 3', value: 'phoneThree' },
    { name: 'Fax 1', value: 'faxOne' },
    { name: 'Email', value: 'email' },
    { name: 'City', value: 'city' },
    { name: 'Area', value: 'area' },
    { name: 'KSA Area', value: 'ksaArea' },
    { name: 'Discount', value: 'discount' },
    { name: 'Is Supplier', value: 'isSupplier' },
    { name: 'Company Type', value: 'companyType' },
    { name: 'Company SubType', value: 'companySubType' },
    { name: 'Country of Origin', value: 'countryOfOrigin' },
    { name: 'Regional Country', value: 'regionalCountry' },
    { name: 'Contact Name', value: 'contactName' },
    { name: 'Contact Phone', value: 'contactPhone' },
    { name: 'Classification', value: 'classification' },
  ];
  itemFields = [
    { name: 'Item Code', value: 'itemCode' },
    { name: 'Family', value: 'family' },
    { name: 'Supplier', value: 'supplier' },
    { name: 'Main Category', value: 'mainCategory' },
    { name: 'Sub Category', value: 'subCategory' },
    { name: 'Shape', value: 'shape' },
    { name: 'IP Code', value: 'ipCode' },
    { name: 'Wattage', value: 'totalWatt' },
    { name: 'Net Lumen', value: 'netLumen' },
    { name: 'Driver', value: 'driver' },
    { name: 'Net Price', value: 'discountedPrice' },
  ];

  fields: any[] = [];
  constructor(private readonly dynamicDialogConfig: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.columnsForm = this.dynamicDialogConfig.data[0];
    if (this.dynamicDialogConfig.data[1] === 'contact') {
      this.fields = this.contactFields;
    } else if (this.dynamicDialogConfig.data[1] === 'company') {
      this.fields = this.companyFields;
    } else if (this.dynamicDialogConfig.data[1] === 'item') {
      this.fields = this.itemFields;
    }
  }
}
