<form
  [formGroup]="inquiryForm"
  [ngClass]="{ 'disabled-form': isType == 'view' }"
>
  <div class="flex flex-row gap-3 mt-1" *ngIf="!isInquiry">
    <p-checkbox
      binary="true"
      name="Is Latest"
      label="Is Latest"
      formControlName="isLatest"
    >
    </p-checkbox>
    <p-checkbox
      binary="true"
      name="Is Quick Offer"
      formControlName="isQuickOffer"
      label="Is Quick Offer"
    >
    </p-checkbox>
    <p-checkbox
      binary="true"
      name="Is Lost"
      label="Is Lost"
      formControlName="isLost"
    >
    </p-checkbox>
    <p-checkbox
      binary="true"
      name="Our Design"
      label="Our Design"
      formControlName="ourDesign"
    >
    </p-checkbox>
  </div>
  <div class="grid">
    <div class="col-4 grid">
      <div class="p-fluid gap-2 mt-2 height-fix flex flex-column col-5">
        <p>Project Name*</p>
        <p>Project Short Name*</p>
        <p>Main Project No.</p>
        <p>Client*</p>
        <p>Contact*</p>
        <p>Main Consultant</p>
        <p>MEP Consultant</p>
        <p>Main Contractor</p>
        <p>MEP Contractor</p>
        <p>Location*</p>
      </div>
      <div class="p-fluid gap-2 flex flex-column col-7">
        <input type="text" formControlName="projectName" pInputText />
        <input
          type="text"
          formControlName="shortName"
          [maxlength]="60"
          pInputText
        />
        <input type="text" formControlName="mainProjectNumber" pInputText />
        <span>
          <p-dropdown
            autoWidth="false"
            [options]="clients"
            formControlName="clientId"
            placeholder=" "
            optionLabel="companyName"
            optionValue="companyId"
            [filter]="true"
            filterBy="companyName"
            [showClear]="true"
          >
          </p-dropdown>
          <p-button
            icon="pi pi-plus"
            class="custom-button"
            (onClick)="viewAddCompany()"
          ></p-button>
        </span>

        <span>
          <p-dropdown
            appendTo="body"
            autoWidth="false"
            [options]="contacts"
            formControlName="contactId"
            placeholder=" "
            optionLabel="fullName"
            optionValue="contactId"
            [filter]="true"
            filterBy="firstName"
            [showClear]="true"
          >
          </p-dropdown>
          <p-button
            icon="pi pi-plus"
            class="custom-button"
            (onClick)="viewAddContact()"
          ></p-button>
        </span>
        <span>
          <p-dropdown
            appendTo="body"
            autoWidth="false"
            [options]="clients"
            formControlName="mainConsultantId"
            placeholder=" "
            optionLabel="companyName"
            optionValue="companyId"
            [filter]="true"
            filterBy="companyName"
            [showClear]="true"
          >
          </p-dropdown>
          <p-button
            icon="pi pi-plus"
            class="custom-button"
            (onClick)="viewAddCompany()"
          ></p-button>
        </span>
        <span>
          <p-dropdown
            appendTo="body"
            autoWidth="false"
            [options]="clients"
            formControlName="mepConsultantId"
            placeholder=" "
            optionLabel="companyName"
            optionValue="companyId"
            [filter]="true"
            filterBy="companyName"
            [showClear]="true"
          >
          </p-dropdown>
          <p-button
            icon="pi pi-plus"
            class="custom-button"
            (onClick)="viewAddCompany()"
          ></p-button>
        </span>
        <span>
          <p-dropdown
            appendTo="body"
            autoWidth="false"
            [options]="clients"
            formControlName="mainContractorId"
            placeholder=" "
            optionLabel="companyName"
            optionValue="companyId"
            [filter]="true"
            filterBy="companyName"
            [showClear]="true"
          >
          </p-dropdown>
          <p-button
            icon="pi pi-plus"
            class="custom-button"
            (onClick)="viewAddCompany()"
          ></p-button>
        </span>
        <span>
          <p-dropdown
            appendTo="body"
            autoWidth="false"
            [options]="clients"
            formControlName="mepContractorId"
            placeholder=" "
            optionLabel="companyName"
            optionValue="companyId"
            [filter]="true"
            filterBy="companyName"
            [showClear]="true"
          >
          </p-dropdown>
          <p-button
            icon="pi pi-plus"
            class="custom-button"
            (onClick)="viewAddCompany()"
          ></p-button>
        </span>
        <p-dropdown
          appendTo="body"
          autoWidth="false"
          [style]="{ height: '2.3rem' }"
          [options]="countries"
          formControlName="location"
          placeholder=" "
          optionLabel="title"
          optionValue="countryId"
          [filter]="true"
          filterBy="title"
          [showClear]="true"
          (onChange)="onCountryChange($event)"
        >
        </p-dropdown>
      </div>
    </div>
    <div class="col-4 grid">
      <div class="p-fluid gap-2 mt-2 height-fix flex flex-column pl-4 col-5">
        <p>Priority*</p>
        <p>Salesman*</p>
        <p *ngIf="!isInquiry">Designer</p>
        <p *ngIf="inquiryForm.get('isLost').value">Project Lost Note</p>
        <p>Source</p>
        <p>Stage</p>
        <p>Status*</p>
        <p>Time-Frame*</p>
        <p *ngIf="showArea">Area *</p>
        <p>Attachments</p>
      </div>
      <div class="p-fluid gap-2 flex flex-column col-7">
        <p-dropdown
          autoWidth="false"
          [style]="{ height: '2.3rem' }"
          [options]="priorityList"
          formControlName="priority"
          placeholder=" "
          optionLabel="value"
          optionValue="value"
          [showClear]="true"
        >
        </p-dropdown>
        <p-dropdown
          autoWidth="false"
          [style]="{ height: '2.3rem' }"
          [options]="salesman"
          formControlName="SalesmanId"
          placeholder=" "
          optionLabel="userName"
          optionValue="userId"
          [filter]="true"
          filterBy="userName"
          [showClear]="true"
        >
        </p-dropdown>
        <p-dropdown
          *ngIf="!isInquiry"
          autoWidth="false"
          [style]="{ height: '2.3rem' }"
          [options]="designers"
          formControlName="designerId"
          placeholder=" "
          optionLabel="userName"
          optionValue="userId"
          [filter]="true"
          filterBy="userName"
          [showClear]="true"
        >
        </p-dropdown>
        <textarea
          *ngIf="inquiryForm.get('isLost').value"
          rows="2"
          class="w-full"
          pInputTextarea
          autoResize="autoResize"
          formControlName="projectLostNote"
        ></textarea>
        <p-dropdown
          autoWidth="false"
          [options]="sources"
          formControlName="sourceId"
          placeholder=" "
          optionLabel="value"
          optionValue="basicId"
          [filter]="true"
          filterBy="value"
          [showClear]="true"
        >
        </p-dropdown>
        <p-dropdown
          autoWidth="false"
          [options]="stages"
          formControlName="stage"
          placeholder=" "
          optionLabel="value"
          optionValue="basicId"
          [filter]="true"
          filterBy="value"
          [showClear]="true"
        >
        </p-dropdown>
        <p-dropdown
          appendTo="body"
          autoWidth="false"
          [options]="statuses"
          formControlName="statusId"
          placeholder=" "
          optionLabel="value"
          optionValue="basicId"
          [filter]="true"
          filterBy="value"
          [showClear]="true"
        >
        </p-dropdown>
        <p-dropdown
          appendTo="body"
          autoWidth="false"
          [options]="timeFrames"
          formControlName="timeFrameId"
          placeholder=" "
          optionLabel="value"
          optionValue="basicId"
          [filter]="true"
          filterBy="value"
          [showClear]="true"
        >
        </p-dropdown>
        <p-dropdown
          *ngIf="showArea"
          autoWidth="false"
          [options]="ksaAreas"
          formControlName="areaId"
          placeholder=" "
          optionLabel="value"
          optionValue="basicId"
        ></p-dropdown>
        <p-button label="Upload File" (click)="fileInput.click()">
          <input
            type="file"
            #fileInput
            (change)="AddFile($event)"
            style="display: none"
            multiple
          />
        </p-button>

        <div *ngIf="files && files.length" class="flex flex-wrap gap-1">
          <div
            *ngFor="let file of files; let index = index"
            key="index"
            class="flex flex-column relative"
          >
            <div>{{ file.name }}</div>
            <button class="delete-button" (click)="deleteFile(index)">x</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4 grid">
      <div class="p-fluid gap-2 mt-2 height-fix flex flex-column col-5">
        <p>Salesman Note*</p>
        <p>Total Types*</p>
        <p>Total Quantity*</p>
        <p>Total Budget*</p>
        <p>Branch*</p>
        <p>Currency*</p>
        <p>Expected Offer Date*</p>
        <p>Expected Closure Date*</p>
      </div>
      <div class="p-fluid gap-2 flex flex-column col-7">
        <textarea
          rows="2"
          class="w-full"
          pInputTextarea
          autoResize="autoResize"
          formControlName="comment"
        ></textarea>
        <p-inputNumber
          formControlName="typesNumber"
          mode="decimal"
          inputId="withoutgrouping"
          [useGrouping]="false"
        >
        </p-inputNumber>
        <p-inputNumber
          formControlName="totalQuantity"
          mode="decimal"
          inputId="withoutgrouping"
          [useGrouping]="false"
        >
        </p-inputNumber>
        <p-inputNumber
          formControlName="totalBudget"
          mode="decimal"
          inputId="withoutgrouping"
          [useGrouping]="false"
        >
        </p-inputNumber>
        <p-dropdown
          autoWidth="false"
          [style]="{ height: '2.3rem' }"
          [options]="branches"
          formControlName="branchId"
          placeholder=" "
          optionLabel="branchName"
          optionValue="branchId"
          [filter]="true"
          filterBy="branchName"
          [showClear]="true"
        >
        </p-dropdown>
        <p-dropdown
          autoWidth="false"
          [style]="{ height: '2.3rem' }"
          [options]="currencies"
          formControlName="currencyId"
          placeholder=" "
          optionLabel="symbol"
          optionValue="currencyId"
          [filter]="true"
          filterBy="symbol"
          [showClear]="true"
        >
        </p-dropdown>

        <span class="gap-1">
          <p-calendar
            formControlName="dateAwarded"
            inputId="template"
            appendTo="body"
            dateFormat="dd.mm.yy"
          >
          </p-calendar>
          <i
            class="pi pi-question-circle align-self-center flex"
            pTooltip="The date needed to submit the offer to the client"
            tooltipPosition="top"
          ></i>
        </span>
        <span class="gap-1">
          <p-calendar
            formControlName="followUpDate"
            inputId="template"
            appendTo="body"
            dateFormat="dd.mm.yy"
          >
          </p-calendar>
          <i
            class="pi pi-question-circle align-self-center flex"
            pTooltip="The date you believe the project would be in hot or negotiation stage for closure"
            tooltipPosition="top"
          ></i>
        </span>
        <p-button
          *ngIf="isType != 'view'"
          label="Submit"
          [disabled]="isSubmitting"
          (onClick)="onSubmit()"
        ></p-button>
      </div>
    </div>
  </div>
</form>
