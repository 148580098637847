<div class="line-height-4 flex flex-wrap justify-content-around gap 2">
  <div *ngFor="let itemField of itemFields">
    <span class="flex flex-column">
      <label>{{ itemField.header }}</label>
      <input
        type="text"
        pInputText
        [disabled]="true"
        [value]="item[itemField.field]"
      />
    </span>
  </div>
  <div class="flex flex-column">
    <label>Discount:</label>
    <p-inputNumber
      [value]="item.discount"
      suffix="%"
      [disabled]="true"
    ></p-inputNumber>
  </div>
  <img
    *ngIf="item.defaultImage"
    class="mt-3"
    [src]="imageBaseUrl + 'resources/images/' + item.defaultImage"
    style="cursor: pointer"
    height="40"
    (click)="imageClick()"
  />
</div>
<p-galleria
  [value]="item.defaultImage"
  [(visible)]="imageDisplay"
  [circular]="false"
  [fullScreen]="true"
  [showItemNavigators]="false"
  [showThumbnails]="false"
  [baseZIndex]="100000"
>
  <img [src]="imageBaseUrl + 'resources/images/' + item.defaultImage" />
</p-galleria>
