import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import {
    DynamicDialogConfig,
    DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { AuthenticateService } from '../../../shared/authenticate.service';
import { Company } from '../../shared/models/company.model';

@Component({
  selector: 'ngx-delete-company',
  templateUrl: './delete-company.component.html',
  styleUrls: ['./delete-company.component.scss'],
})
export class DeleteCompanyComponent implements OnInit {
  company: Company;
  ActiveUserId: any;
  Identity: any;

  constructor(
    private readonly http: HttpClient,
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private authService: AuthenticateService,
    private storage: StorageMap
  ) {}

  ngOnInit(): void {
    this.company = this.dynamicDialogConfig.data;
    this.storage.get('Identity').subscribe((user) => {
      this.Identity = user;
      this.ActiveUserId = this.Identity.userId;
    });
  }

  deleteCompany(ComapnyId: any) {
    this.http
      .get<any>('company/DeleteCompany/' + ComapnyId + '/' + this.ActiveUserId)
      .subscribe();
    this.ref.close();
  }
  cancelDelete() {
    this.ref.close();
  }
}
