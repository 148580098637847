import { ActionReducer, createReducer, on } from '@ngrx/store';
import { signIn, signInFailure, signInSuccess, signOut } from './auth.actions';
import { AuthState } from './auth.state';

export const authInitialState: AuthState = {
  pending: false,
  user: null,
  error: null,
};

export const authReducer: ActionReducer<AuthState> = createReducer(
  authInitialState,
  on(
    signIn,
    (state): AuthState => ({
      ...state,
      pending: true,
      user: null,
    })
  ),
  on(
    signInSuccess,
    (state, { response }): AuthState => ({
      ...state,
      pending: false,
      user: response,
    })
  ),
  on(
    signInFailure,
    (state, { error }): AuthState => ({
      ...state,
      pending: false,
      user: null,
      error: error,
    })
  ),
  on(
    signOut,
    (): AuthState => ({
      ...authInitialState,
      user: null,
    })
  )
);
